import React, { useEffect, useState } from "react";
import { rubles } from "../_helpers/FormatStrings";
import { Spinner } from "reactstrap";
import { useAuthContext } from "../_services/authentication.service";
import { Link, Redirect, useHistory } from "react-router-dom";
import { Loading } from "../_components/Loading";
import Routes from "../Routes";
import { PublicOffer } from "./PublicOffer";

const ProcessBuy = ({ itemId, itemType, itemPrice }) => {
  const { user, requestBackend } = useAuthContext();
  const history = useHistory();
  const [paymentProcessResult, setPaymentProcessResult] = useState(null);
  const itemRoute = Routes.getActual(itemType, itemId);

  useEffect(() => {
    if (paymentProcessResult) return;
    requestBackend({
        url: `sales/${itemType}/${itemId}`,
        method: "POST",
        onSuccess: (result) => {
          setPaymentProcessResult(result);
        },
        onFail: () => {
          setPaymentProcessResult({ statusCode: -99 });
        },
      });
  }, [itemId, itemType, itemPrice, user, requestBackend, paymentProcessResult]);

  // оплата еще не начата
  if (paymentProcessResult == null) {
    return (
      <>
        <p>Подождите, выполняется обработка...</p>
        <Loading />
      </>
    );
  }

  if (paymentProcessResult.status < 0) {
    return (
      <>
        <p>Произошла ошибка при оплате. Поробуйте позже.</p>
        <Link to={Routes.Catalog.route}>Вернуться в каталог</Link>
      </>
    );
  }

  // enrolled
  if (paymentProcessResult.status === 1) {
    return (
      <>
        <p>Вы успешно зачислены. <Link to={itemRoute.route}>Перейти</Link> к содержанию</p>
        <Redirect to={{pathname: itemRoute.route}} />
      </>
    );
  }

  // accept public offer
  if (paymentProcessResult.status === 3) {
    return (
      <PublicOffer
        onSubmit={() => {
          requestBackend({
            url: `sales/publicoffer/accept`,
            method: "POST",
            onSuccess: () => {
              setPaymentProcessResult(null);
            },
            onFail: () => {
              setPaymentProcessResult({ statusCode: -99 });
            },
          })
        }}
        onCancel={() => {history.push(Routes.Catalog.route)}}
      />
    );
  }

  // redirect to bank
  if (paymentProcessResult.status === 2 && paymentProcessResult.targetUrl) {
    window.location.assign(paymentProcessResult.targetUrl);
    return (
      <div>
        <p>
          Пожалуйста, подождите. Сейчас вы будете перенаправлены на страницу
          платежной системы...
        </p>
        <p>
          Нажмите <a href={paymentProcessResult.bankPageUrl}>сюда</a>, если ваш
          браузер не поддерживает перенаправление
        </p>
        <Spinner />
      </div>
    );
  }

  // произошла ошибка
  console.error(paymentProcessResult);
  return <p>Произошла ошибка при обработке запроса. Попробуйте зайти позже.</p>;
};

export const BuyLecture = ({ lecture, location }) => {
  const lectureInfo = (
    <p>
      Вы перешли на страницу покупки лекции "{lecture.title}" стоимостью{" "}
      {rubles(lecture.price)}
    </p>
  );

  return (
    <>
      {lectureInfo}
      <ProcessBuy
        itemId={lecture.id}
        itemType="Lecture"
        itemPrice={lecture.price}
        location={location}
      />
    </>
  );
};

export const BuyTrack = ({ track, location }) => {
  const trackInfo = (
    <p>
      Вы перешлина страницу покупки трека "{track.title}" стоимостью{" "}
      {rubles(track.price)}
    </p>
  );

  return (
    <>
      {trackInfo}
      <ProcessBuy
        itemId={track.id}
        itemType="Track"
        itemPrice={track.price}
        location={location}
      />
    </>
  );
};
