import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import AdminTablePagination from "./AdminTablePagination";
import AdminTableHeader from "./AdminTableHeader";
import AdminTableBody from "./AdminTableBody";

import "./AdminTable.css";

const AdminTable = ({ fields, data, editRow, deleteRow }) => {
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [sorting, setSorting] = useState(undefined);
  const [filters, setFilters] = useState([]);
  const [visibleData, setVisibleData] = useState(data);

  useEffect(() => {
    let vData =
      filters.length > 0
        ? data.filter((row) => filters.every((filter) => filter.predicate(row)))
        : data;
    if (!!sorting)
      vData.sort((r1, r2) => {
        const f = fields.find((f) => f.name === sorting.field);
        if (!f) return 0;
        const v1 =
          (f.sortAs && f.sortAs(r1)) ||
          (f.displayAs && f.displayAs(r1)) ||
          r1[sorting.field];
        const v2 =
          (f.sortAs && f.sortAs(r2)) ||
          (f.displayAs && f.displayAs(r2)) ||
          r2[sorting.field];
        return v1 == v2 ? 0 : v1 > v2 && sorting.desc ? 1 : -1;
      });
    setVisibleData(vData);
  }, [filters, sorting, fields, data]);

  if (!data || !fields) return <></>;

  return (
    <div className="table-container">
      <table>
        <AdminTableHeader
          fields={fields}
          setFilters={setFilters}
          sorting={sorting}
          setSorting={setSorting}
        />
        {!data.length ? (
          <tr>Список пуст</tr>
        ) : (
          <AdminTableBody
            fields={fields}
            dataOnPage={visibleData.slice(
              page * pageSize,
              (page + 1) * pageSize
            )}
            editRow={editRow}
            deleteRow={deleteRow}
          />
        )}
      </table>
      <AdminTablePagination
        page={page}
        setPage={setPage}
        pageSize={pageSize}
        setPageSize={setPageSize}
        pagesCount={Math.ceil(visibleData.length / pageSize)}
      />
    </div>
  );
};

AdminTable.propTypes = {
  fields: PropTypes.array,
  data: PropTypes.array,
  editRow: PropTypes.func,
  deleteRow: PropTypes.func,
};

export default AdminTable;
