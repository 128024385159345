import React, { useEffect, useState } from "react";
import $ from "jquery";
import Routes from "../../Routes";
import * as Yup from "yup";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { useAuthContext } from "../../_services/authentication.service";
import { Loading } from "../../_components/Loading";
import { Alert } from "reactstrap";
import AdminUpdate from "../_Proto/AdminUpdate";
import { getMediaUrl } from "../../_helpers/backend.url";

const AdminTrackUpdate = ({ match }) => {
  const id = match.params.id;
  const route = Routes.getActual("AdminTrackEdit", id);
  const { requestBackend } = useAuthContext();
  const [departments, setDepartments] = useState([]);
  const [specialities, setSpecialities] = useState([]);
  const [sections, setSections] = useState([]);
  const [lectures, setLectures] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (!loading) return;
    setError(false);
    Promise.all([
      $.when(
        requestBackend({
          url: "admin/departments",
          onSuccess: (data) => setDepartments(data),
        })
      ).catch(() => {
        setLoading(false);
        setError("Произошла ошибка при загрузке списка кафедр");
      }),
      $.when(
        requestBackend({
          url: "admin/sections",
          onSuccess: (data) => setSections(data),
        })
      ).catch(() => {
        setLoading(false);
        setError("Произошла ошибка при загрузке списка разделов");
      }),
      $.when(
        requestBackend({
          url: "admin/specialities",
          onSuccess: (data) => setSpecialities(data),
        })
      ).catch(() => {
        setLoading(false);
        setError("Произошла ошибка при загрузке списка специальностей");
      }),
      $.when(
        requestBackend({
          url: "admin/lectures",
          onSuccess: (data) => setLectures(data),
        })
      ).catch(() => {
        setLoading(false);
        setError("Произошла ошибка при загрузке списка лекций");
      }),
    ]).then(() => {
      setLoading(false);
    });
  }, []);

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <Alert color="danger">{error}</Alert>;
  }

  return (
    <>
      <BreadcrumbsItem to={route.route}>{route.title}</BreadcrumbsItem>
      <h1>Редактирование трека {id}</h1>
      <AdminUpdate
        loadUrl={"admin/tracks/" + id}
        submitUrl="admin/tracks"
        fields={[
          {
            name: "id",
            label: "Id трека",
            inputType: "none",
          },
          {
            name: "title",
            label: "Название трека",
            validation: Yup.string().required(
              "Название трека обязательно для заполнения"
            ),
            inputType: "text",
          },
          {
            name: "onSale",
            label:
              "Выставить на продажу (показывать в каталоге трек и входящие в него лекции)",
            inputType: "boolean",
          },
          {
            name: "sectionId",
            label: "Раздел",
            inputType: "select",
            options: sections.map((s) => ({ id: s.id, label: s.title })),
            initialField: "section",
            initialMap: (v) => v?.id,
          },
          {
            name: "departmentId",
            label: "Кафедра",
            inputType: "select",
            options: departments.map((d) => ({ id: d.id, label: d.title })),
            initialField: "department",
            initialMap: (v) => v?.id,
          },
          {
            name: "specialityId",
            label: "Специальность",
            inputType: "select",
            options: specialities.map((s) => ({ id: s.id, label: s.title })),
            initialField: "speciality",
            initialMap: (v) => v?.id,
          },
          {
            name: "annotation",
            label: "Аннотация",
            inputType: "richtext",
          },
          {
            name: "lectureIds",
            label: "Лекции в треке",
            labelSelected: "Выбранные лекции",
            labelUnselected: "Доступные лекции",
            inputType: "selectMany",
            options: lectures.map((l) => ({
              id: l.id,
              label: l.title,
              imageUrl: getMediaUrl(l.image?.id),
            })),
            initialField: "lectures",
            initialMap: (lectures) =>
              lectures?.map((l) => ({
                id: l.id,
                label: l.title,
                imageUrl: getMediaUrl(
                  l.image?.id ||
                    lectures.find((l0) => l0.id === l.id)?.image?.id
                ),
              })),
            postAs: (lectures) => lectures?.map((l) => l.id) || [],
          },
        ]}
        redirectTo={Routes.AdminTracks.route}
      />
    </>
  );
};

export default AdminTrackUpdate;
