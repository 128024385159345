import React, { Component } from "react";
import { Route, Router, Switch, Redirect } from "react-router";
import Routes from "./Routes";
import { Layout } from "./Layout/Layout";
import { CatalogPage } from "./Catalog/CatalogPage";
import { PrivateRoute } from "./_components/PrivateRoute";
import { history } from "./_helpers/history";
import { AdminPage } from "./Admin/AdminPage";
import { AccountPage } from "./Account/AccountPage";
import { EditPublicOffer } from "./Admin/EditPublicOffer";
import { Role } from "./_helpers/role";
import { AuthContextProvider } from "./_services/authentication.service";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { NotFoundPage } from "./Errors/NotFoundPage";
import { Logout } from "./Account/Logout";
import { EmbeddedVideo } from "./_components/EmbeddedVideo";

import "./_css/ColorScheme.css";
import "./_css/Sizes.css";
import "./_css/custom.css";
import { AccountCatalog } from "./Account/AccountCatalog";

export default class App extends Component {
  static displayName = App.name;

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Router history={history}>
        <BreadcrumbsItem to={Routes.Home.route}>
          {Routes.Home.title}
        </BreadcrumbsItem>
        <AuthContextProvider>
          <Layout>
            <Switch>
              <Route
                exact
                path={Routes.Home.route}
                render={() => <Redirect to={Routes.Catalog.route} />}
              />
              <Route path={Routes.Logout.route} component={Logout} />
              <Route path={Routes.Catalog.route} component={CatalogPage} />
              <PrivateRoute
                path={Routes.Account.route}
                component={AccountPage}
              />
              <PrivateRoute
                roles={[Role.User]}
                path={Routes.AccountCatalog.route}
                component={AccountCatalog}
              />
              <PrivateRoute
                roles={[Role.Admin, Role.Manager]}
                path={Routes.EditPublicOffer.route}
                component={EditPublicOffer}
              />
              <PrivateRoute
                roles={[Role.Admin, Role.Manager]}
                path={Routes.Admin.route}
                component={AdminPage}
              />
              <PrivateRoute
                roles={[Role.Admin, Role.Manager]}
                path={Routes.AdminHelp.route}
                onEnter={() => window.location.reload()}
              />
              <PrivateRoute
                path={Routes.VideoStream.route}
                component={EmbeddedVideo}
              />
              <Route component={NotFoundPage} />
            </Switch>
          </Layout>
        </AuthContextProvider>
      </Router>
    );
  }
}
