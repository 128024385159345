import React from "react";
import Routes from "../../Routes";
import * as Yup from "yup";
import AdminUpdate from "../_Proto/AdminUpdate";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";

const AdminSectionUpdate = ({ match }) => {
  const id = match.params.id;
  return (
    <>
      <BreadcrumbsItem to={Routes.AdminSectionEdit.route}>
        {Routes.AdminSectionEdit.title}
      </BreadcrumbsItem>
      <h1>Редактирование раздела {match.params.id}</h1>
      <AdminUpdate
        loadUrl={`admin/sections/${id}`}
        submitUrl="admin/sections"
        fields={[
          {
            name: "title",
            label: "Название раздела",
            validation: Yup.string().required(
              "Название раздела обязательно для заполнения"
            ),

            inputType: "text",
            acceptFileTypes: "",
          },
        ]}
        redirectTo={Routes.AdminSections.route}
      />
    </>
  );
};

export default AdminSectionUpdate;
