import React, { useEffect, useState } from "react";
import Button from "reactstrap/lib/Button";
import Col from "reactstrap/lib/Col";
import ListGroup from "reactstrap/lib/ListGroup";
import ListGroupItem from "reactstrap/lib/ListGroupItem";
import Row from "reactstrap/lib/Row";
import { Loading } from "./Loading";
import { SearchBar } from "./SearchBar";

const SelectManyListItem = ({ item, onSelect }) => {
  const [active, setActive] = useState(false);
  useEffect(() => {
    item.active = active;
    onSelect(active);
  }, [active, item, onSelect]);
  return (
    <ListGroupItem
      active={active}
      style={{ cursor: "pointer", padding: "0.25rem" }}
      onClick={() => {
        setActive((a) => !a);
      }}
    >
      <div>
        {item.imageUrl && (
          <img src={item.imageUrl} alt="" style={{ height: "2rem" }} />
        )}
        <span className="mx-2 my-auto">{item.label}</span>
      </div>
    </ListGroupItem>
  );
};

const SelectManyList = ({ items, onCountChange }) => {
  const [count, setCount] = useState(0);
  const [search, setSearch] = useState(undefined);

  useEffect(() => {
    onCountChange(count);
  }, [count, onCountChange]);

  const filteredItems =
    items && search
      ? items.filter((u) => u.label.toLowerCase().includes(search))
      : items;

  return (
    <>
      <SearchBar
        placeholder="Поиск"
        onSearch={(value) => setSearch(value.toLowerCase())}
      />
      <div
        style={{ height: "280px", overflowY: "auto", padding: "0" }}
        className="form-control"
      >
        <ListGroup flush>
          {filteredItems &&
            filteredItems.map((item) => (
              <SelectManyListItem
                key={item.id}
                item={item}
                onSelect={() => setCount(items.filter((i) => i.active).length)}
              />
            ))}
        </ListGroup>
      </div>
    </>
  );
};

const SelectMany = ({
  labelSelected,
  labelUnselected,
  all,
  selected,
  onChange,
}) => {
  const [canAdd, setCanAdd] = useState(false);
  const [canRemove, setCanRemove] = useState(false);

  const addSelected = () => {
    const newSelected = all.filter((item) => item.active);
    onChange([...selected, ...newSelected]);
    newSelected.forEach((item) => {
      item.active = undefined;
    });
  };
  const removeSelected = () => {
    onChange(selected.filter((a) => !a.active));
  };

  if (!selected || !all) {
    return <Loading />;
  }

  return (
    <Row>
      <Col>
        <label>{labelSelected}</label>
        <SelectManyList
          items={selected}
          onCountChange={(cnt) => setCanRemove(cnt > 0)}
        />
      </Col>

      <Col className="col-2 d-flex flex-column justify-content-center">
        <Button className="my-3" onClick={addSelected} disabled={!canAdd}>
          Добавить &lt;&lt;
        </Button>
        <Button className="my-3" onClick={removeSelected} disabled={!canRemove}>
          Удалить &gt;&gt;
        </Button>
      </Col>

      <Col>
        <label>{labelUnselected}</label>
        <SelectManyList
          items={all.filter((item) => !selected.some((a) => a.id === item.id))}
          onCountChange={(cnt) => setCanAdd(cnt > 0)}
        />
      </Col>
    </Row>
  );
};

export default SelectMany;
