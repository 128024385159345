import React, { useState } from "react";
import { Button } from "reactstrap";
import { Link } from "react-router-dom";
import { useAuthContext } from "../_services/authentication.service";
import { Role } from "../_helpers/role";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog } from "@fortawesome/free-solid-svg-icons";
import Routes from "../Routes";
import VideoPopup from "./VideoPopup";
import { VideoPlayer } from "../_components/VideoPlayer/VideoPlayer";
import { getKurentoUrl, getMediaUrl } from "../_helpers/backend.url";

function userCanEdit(user) {
  return user?.roles?.some((r) => r === Role.Admin || r === Role.Manager);
}

export const LecturePlayVideo = ({
  title,
  video,
  videoEmbedded,
  width = "auto",
}) => {
  const [videoPlaying, setVideoPlaying] = useState(null);
  if (!video) return null;
  if (videoEmbedded) {
    return (
      <VideoPlayer
        previewUrl={video.previewId && getMediaUrl(video.previewId)}
        videoUrl={video.url || getKurentoUrl(video.code)}
        size="maximised"
        maxWidth="50%"
      />
    );
  }
  return (
    <div style={{ width, margin: "0.5rem 0" }}>
      <VideoPopup
        title={title}
        video={videoPlaying}
        setVideo={setVideoPlaying}
      />
      <Button
        className="btn-primary px-4"
        style={{ width }}
        onClick={() => setVideoPlaying(video)}
      >
        Смотреть
      </Button>
    </div>
  );
};

export const LectureLink = ({ id, width = "auto" }) => {
  const route = Routes.getActual("Lecture", id);
  return (
    <a href={route.route} style={{ width, margin: "0.5rem 0" }}>
      <Button className="btn-primary px-4" style={{ width }}>
        Перейти
      </Button>
    </a>
  );
};

export const BuyLink = ({ id, itemType, itemName, price, width = "auto" }) => {
  return (
    <Link
      to={Routes.getActual(itemType + "Buy", id).route}
      style={{ width, margin: "0.5rem 0" }}
    >
      <Button
        className={"btn-primary px-" + (price ? "5" : "3")}
        style={{ width }}
      >
        Купить {itemName}
        {price !== undefined && (
          <>
            {" "}
            за{" "}
            {price.toLocaleString("ru-RU", {
              style: "currency",
              currency: "RUB",
              minimumFractionDigits: 0,
            })}
          </>
        )}
      </Button>
    </Link>
  );
};

export const EditLink = ({ id, itemType }) => {
  return (
    <Link
      to={Routes.getActual("Admin" + itemType + "Edit", id).route}
      style={{ position: "absolute", right: "0.5rem", top: "0.25rem" }}
    >
      <FontAwesomeIcon
        icon={faCog}
        style={{ filter: "drop-shadow(0px 0px 5px var(--app-bg-color))" }}
      />
    </Link>
  );
};

export const LectureActionLink = ({
  lecture,
  showPrice,
  videoEmbedded,
  width = "auto",
}) => {
  const { user } = useAuthContext();
  if (!lecture) return null;
  return (
    <>
      {lecture.enrolledByCurrentUser ? (
        <LecturePlayVideo
          className="m-1"
          title={lecture.title}
          video={lecture.video}
          videoEmbedded={videoEmbedded}
          width={width}
        />
      ) : (
        <BuyLink
          id={lecture.id}
          itemType="Lecture"
          itemName="лекцию"
          price={(showPrice && lecture.price) || undefined}
          width={width}
        />
      )}
      {userCanEdit(user) && (
        <EditLink className="m-1" itemType="Lecture" id={lecture.id} />
      )}
    </>
  );
};

export const TrackActionLink = ({ track, width = "auto" }) => {
  const { user } = useAuthContext();
  if (!track) return null;
  return (
    <>
      {!track.enrolledByCurrentUser && (
        <BuyLink
          id={track.id}
          itemType="Track"
          itemName="трек"
          price={track.price}
          width={width}
        />
      )}
      {userCanEdit(user) && (
        <EditLink className="m-1" itemType="Track" id={track.id} />
      )}
    </>
  );
};
