import React from "react";
import { Card, CardImg, CardBody } from "reactstrap";
import { abbreviatedName } from "../_helpers/FormatStrings";
import { getMediaUrl } from "../_helpers/backend.url";

import "./AuthorInfo.css";

const AuthorInfoShort = ({ author }) => (
  <span key={author.id} className="authorshort">
    <strong>
      {abbreviatedName([
        author.personalName,
        author.patronymic,
        author.familyName,
      ])}
    </strong>{" "}
    {author.description && author.description.replace(/<[^>]*>/g, "")}
  </span>
);

export const AuthorsInfoShort = ({ authors }) => {
  if (!authors) return null;
  if (typeof authors === "string")
    return (
      <>
        <strong>Авторы:</strong>
        {authors}
      </>
    );
  if (!authors.length) return <></>;
  if (authors.length === 1) return <AuthorInfoShort author={authors[0]} />;
  return (
    <>
      <strong>Авторы:</strong>
      {authors.map((author) => (
        <AuthorInfoShort author={author} key={"author-" + author.id} />
      ))}
    </>
  );
};

// TODO change link on back
export const AuthorInfo = ({ author }) =>
  author && (
    <Card
      kay={author.id}
      className="border-light my-3 p-1"
      style={{ backgroundColor: "transparent", fontSize: "80%" }}
    >
      {author.fotoId && <CardImg src={getMediaUrl(author.fotoId)} />}
      <CardBody className="p-1 mt-3">
        <strong>
          Автор лекции:{" "}
          {abbreviatedName([
            author.personalName,
            author.patronymic,
            author.familyName,
          ])}
        </strong>
        {author.description && (
          <span dangerouslySetInnerHTML={{ __html: author.description }} />
        )}
      </CardBody>
    </Card>
  );
