export function getRusNumbersEnding(count, ending1, ending2, ending5) {
  let count10 = count % 10;
  let count100 = count % 100;
  if (count10 === 1 && count100 !== 11) {
    return ending1;
  }
  if (count10 >= 2 && count10 <= 4 && !(count100 >= 11 && count100 <= 14)) {
    return ending2;
  }
  return ending5;
}

export function nameParts(fullName) {
  let [firstname, middlename, lastname] = fullName.split(" ");
  if (!lastname) {
    lastname = middlename;
    middlename = "";
  }
  return [firstname, middlename, lastname];
}

export function abbreviatedName(fullName) {
  let [firstname, middlename, lastname] = Array.isArray(fullName)
    ? fullName
    : nameParts(fullName);
  return `${lastname} ${firstname[0]}.${middlename ? middlename[0] + "." : ""}`;
}

export function userName(fullName) {
  try {
    let [firstname, middlename, lastname] = Array.isArray(fullName)
      ? fullName
      : nameParts(fullName);
    return `${
      middlename ? firstname[0] + "." + middlename[0] + "." : firstname
    } ${lastname}`;
  } catch {
    return "";
  }
}

export function rubles(amount, digits = 0) {
  return amount.toLocaleString("ru-ru", {
    style: "currency",
    currency: "RUR",
    minimumFractionDigits: digits,
  });
}
