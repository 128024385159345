import React from "react";
import { MediaRoutes } from "../../Routes";
import "./VideoPlayer.css";

export const VideoPlayer = ({ previewUrl, videoUrl, size, maxWidth }) => {
  const fullPreviewUrl = new URL(
    previewUrl || MediaRoutes.DefultVideoPreview,
    window.location.href
  );
  return videoUrl ? (
    <div
      className={"video-container " + (size || "")}
      style={{ maxWidth: maxWidth }}
    >
      <iframe
        title="promovideo"
        src={`${videoUrl}&poster=${fullPreviewUrl}`}
        scrolling="no"
        frameBorder="0"
        allow="autoplay; fullscreen"
        allowFullScreen="allowfullscreen"
      />
    </div>
  ) : (
    ""
  );
};
