import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { Button } from "reactstrap";

const AdminTableBody = ({ fields, dataOnPage, editRow, deleteRow }) => {
  function getValue(row, field) {
    if (field.displayAs) {
      return field.displayAs(row);
    }
    return row[field.name];
  }
  return (
    <tbody>
      {dataOnPage.map((row, i) => (
        <tr key={"row-" + i}>
          {fields.map((field) => (
            <td
              key={"cell-" + i + "-" + field.name}
              className={
                field.dataType === "number" || field.dataType === "currency"
                  ? "text-right"
                  : field.dataType === "boolean"
                  ? "text-center"
                  : ""
              }
            >
              {field.dataType === "boolean" ? (
                <input
                  type="checkbox"
                  readOnly
                  checked={getValue(row, field)}
                  contentEditable={false}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                />
              ) : field.dataType === "datetime" ? (
                getValue(row, field).toLocaleString()
              ) : field.dataType === "currency" ? (
                getValue(row, field).toLocaleString("ru-RU", {
                  style: "currency",
                  currency: "RUB",
                })
              ) : (
                getValue(row, field)
              )}
            </td>
          ))}
          <td>
            {editRow && (
              <Button
                onClick={() => editRow(row)}
                color="primary"
                className="px-1 py-0"
              >
                <FontAwesomeIcon icon={faEdit} />
              </Button>
            )}
            {deleteRow && (
              <Button
                onClick={() => deleteRow(row)}
                color="danger"
                className="px-1 py-0"
              >
                <FontAwesomeIcon icon={faTrashAlt} />
              </Button>
            )}
          </td>
        </tr>
      ))}
    </tbody>
  );
};

AdminTableBody.propTypes = {
  fields: PropTypes.array,
  dataOnPage: PropTypes.array,
  editRow: PropTypes.func,
  deleteRow: PropTypes.func,
};

export default AdminTableBody;
