import React from "react";
import { Link } from "react-router-dom";
import Routes, { MediaRoutes } from "../Routes";
import { EnrollerdUsersCount } from "./EnrolledUsersCount";
import { AuthorsInfoShort } from "./AuthorInfo";
import { MarkupedText } from "../_components/MarkupedText";
import { LectureActionLink, TrackActionLink } from "./CatalogActions";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { ImagePlugged } from "../_components/ImagePlugged";
import { Mediacontent } from "../_components/Mediacontent";
import { getMediaUrl } from "../_helpers/backend.url";

import "./TrackPage.css";

const LectureItem = ({ lecture }) => {
  const lectureRoute = Routes.getActual("Lecture", lecture.id, lecture.title);
  return (
    <div className="trackpage-lecture position-relative">
      <div className="trackpage-lecture-main">
        <Link to={lectureRoute.route}>
          <h3>{lectureRoute.title}</h3>
        </Link>
        <EnrollerdUsersCount count={lecture.enrolledCount} />
        <AuthorsInfoShort authors={lecture.authors} />
        {lecture.annotation && (
          <div className="my-2">
            <MarkupedText description={lecture.annotation} />
          </div>
        )}
      </div>
      <div className="trackpage-lecture-aside">
        <ImagePlugged
          src={getMediaUrl(lecture.imageId)}
          plugSrc={MediaRoutes.DefultLecturePoster}
          className="trackpage-lecture-img"
          alt={lecture.title}
        />
        <LectureActionLink lecture={lecture} showPrice={true} width="100%" />
      </div>
    </div>
  );
};

export const TrackDetails = ({ track }) => {
  const thisRoute = Routes.getActual("Track", track.id, track.title);

  return (
    <div className="position-relative">
      <BreadcrumbsItem to={thisRoute.route}>{thisRoute.title}</BreadcrumbsItem>

      <h1>{track.title}</h1>
      <TrackActionLink track={track} />

      <EnrollerdUsersCount count={track.enrolledCount} itemType={"Трек"} />
      <div className="my-3">
        <AuthorsInfoShort authors={track.authors} />
      </div>
      <h4>Аннотация</h4>
      <MarkupedText description={track.annotation} />

      <div>
        <h4>Состав трека</h4>
        {track.lectures &&
          track.lectures.map((lecture) => (
            <LectureItem key={lecture.id} lecture={lecture} />
          ))}
      </div>
    </div>
  );
};
