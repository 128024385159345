import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  faSort,
  faSortDown,
  faSortUp,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "reactstrap";

const SearchBoolean = ({ field, changeFilters }) => {
  return (
    <div className="admin-header-search">
      <select
        onChange={(e) => {
          const value = e.target.value;
          changeFilters(
            field.name,
            value &&
              ((row) => (value == 1 ? row[field.name] : !row[field.name]))
          );
        }}
      >
        <option value={undefined}></option>
        <option value={1}>Да</option>
        <option value={-1}>Нет</option>
      </select>
    </div>
  );
};

const SearchRange = ({ field, changeFilters }) => {
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");

  useEffect(() => {
    changeFilters(
      field.name,
      (from || to) &&
        ((row) =>
          row[field.name] >=
            (!from ||
              (field.dataType === "datetime" ? new Date(from) : +from)) &&
          (!to ||
            row[field.name] <
              (field.dataType === "datetime" ? new Date(to) : +to)))
    );
  }, [from, to]);

  return (
    <div>
      <div className="admin-header-search">
        <input
          size={0}
          type={field.dataType === "datetime" ? "date" : "numeric"}
          value={from}
          onChange={(e) => setFrom(e.target.value)}
        />
      </div>
      <div className="admin-header-search">
        <input
          type={field.dataType === "datetime" ? "date" : "numeric"}
          value={to}
          onChange={(e) => setTo(e.target.value)}
        />
      </div>
    </div>
  );
};

const SearchText = ({ field, changeFilters }) => {
  function getValue(row, field) {
    if (field.displayAs) {
      return "" + field.displayAs(row);
    }
    return "" + row[field.name];
  }
  return (
    <div className="admin-header-search">
      <input
        type="text"
        size={0}
        onChange={(e) => {
          const value = e.target.value;
          changeFilters(
            field.name,
            value &&
              ((row) => {
                return getValue(row, field)
                  .toLowerCase()
                  .includes(value?.toLowerCase());
              })
          );
        }}
      />
    </div>
  );
};

const AdminTableHeader = ({ fields, setFilters, sorting, setSorting }) => {
  function changeFilters(fieldName, predicate) {
    setFilters((filters) =>
      predicate
        ? [
            ...filters.filter((f) => f.field !== fieldName),
            {
              field: fieldName,
              predicate: predicate,
            },
          ]
        : [...filters.filter((f) => f.field !== fieldName)]
    );
  }

  return (
    <thead>
      <tr>
        {fields.map((field) => (
          <th key={"title-" + field.name}>
            {field.title}
            <Button
              color="link"
              size="sm"
              onClick={() =>
                setSorting((sorting) => ({
                  field: field.name,
                  desc: sorting?.field === field.name ? !sorting.desc : false,
                }))
              }
            >
              {sorting?.field === field.name ? (
                <FontAwesomeIcon icon={sorting?.desc ? faSortDown : faSortUp} />
              ) : (
                <FontAwesomeIcon icon={faSort} />
              )}
            </Button>
          </th>
        ))}
        <th></th>
      </tr>
      <tr>
        {fields.map((field) => (
          <th key={"title-" + field.name}>
            {field.dataType === "boolean" ? (
              <SearchBoolean field={field} changeFilters={changeFilters} />
            ) : field.dataType === "numeric" ||
              field.dataType === "currency" ||
              field.dataType === "datetime" ? (
              <SearchRange field={field} changeFilters={changeFilters} />
            ) : (
              <SearchText field={field} changeFilters={changeFilters} />
            )}
          </th>
        ))}
        <th></th>
      </tr>
    </thead>
  );
};

AdminTableHeader.propTypes = {
  fields: PropTypes.array,
  setSearch: PropTypes.func,
};

export default AdminTableHeader;
