import React, { useState, useEffect } from "react";
import $ from "jquery";
import { CatalogList } from "./CatalogList";
import { useAuthContext } from "../_services/authentication.service";
import { useHistory, useLocation } from "react-router-dom";
import { QueryParams } from "./QueryParams";
import { Col, Row } from "reactstrap";
import { doFilterSort } from "../_helpers/filter.sort";
import CatalogFilters, { getFiltersFromParams } from "./CatalogFilters";
import { Loading } from "../_components/Loading";
import { SearchBar } from "../_components/SearchBar";
import CatalogSorting from "./CatalogSorting";

export const Catalog = ({ enrolledOnly }) => {
  const history = useHistory();
  const location = useLocation();
  const [params] = useState(
    new URLSearchParams(
      window.sessionStorage.getItem(location.pathname + "-params") || ""
    )
  );

  const { user, requestBackend } = useAuthContext();
  const [catalog, setCatalog] = useState(null);
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState(getFiltersFromParams(params));
  const [sorting, setSorting] = useState(
    params.has(QueryParams.sortBy)
      ? {
          field: params.get(QueryParams.sortBy),
          descending: params.has(QueryParams.sortDesc),
        }
      : { field: "fullName", descending: false }
  );
  const [groupByTrack, setGroupByTrack] = useState(
    !!params.get(QueryParams.groupByTrack) || true
  );

  useEffect(() => {
    setLoading(true);
  }, [user]);

  // load catalog
  useEffect(() => {
    if (!loading) return;
    $.when(
      requestBackend({
        url: enrolledOnly ? "catalog/enrolled" : "catalog",
        onSuccess: (data) => {
          data.lectures = [
            ...data.singleLectures,
            ...data.tracks.reduce((all, t) => [...all, ...t.lectures], []),
          ].map((l) => ({ ...l, type: "lecture" }));
          data.tracks = data.tracks.map((t) => ({ ...t, type: "track" }));
          setCatalog(data);
        },
      })
    ).then(function () {
      setLoading(false);
    });
  }, [loading, user]);

  useEffect(() => {
    window.sessionStorage.setItem(
      location.pathname + "-params",
      location.search
    );
  }, [location.search]);

  useEffect(() => {
    setParam(QueryParams.groupByTrack, groupByTrack);
  }, [groupByTrack]);

  const setFilterValue = (field, value) => {
    let newFilters = Object.assign({}, filters);
    newFilters[field] = { ...newFilters[field], value };
    setFilters(newFilters);
    setParam(QueryParams.filters + field, value);
  };

  const setSortingValue = (field, descending) => {
    if (descending === undefined) {
      descending = field === sorting.field && !sorting.descending;
    }
    setSorting({ field: field, descending: descending });
    setParam(QueryParams.sortBy, field);
    setParam(QueryParams.sortDesc, descending);
  };

  // сохранить значение параметра в URL и сессии браузера
  function setParam(name, value) {
    if (!value && value !== false && value !== 0) params.delete(name);
    else params.set(name, value);
    if (history.location.search !== "?" + params.toString()) {
      history.replace({
        search: "?" + params.toString(),
      });
    }
    window.sessionStorage.setItem(
      location.pathname + "-params",
      params.toString()
    );
  }

  let visibleList = [];
  if (catalog) {
    if (groupByTrack) {
      let filteredTracks = doFilterSort(catalog.tracks, filters, sorting);
      const filteredLectures = doFilterSort(
        catalog.singleLectures,
        filters,
        sorting
      );
      visibleList = [...filteredTracks, ...filteredLectures];
    } else {
      visibleList = doFilterSort(catalog.lectures, filters, sorting) || [];
    }
  }

  return (
    <>
      <Row>
        <Col lg="3">
          <CatalogFilters
            filters={filters}
            setFilterValue={setFilterValue}
            groupByTrack={groupByTrack}
            setGroupByTrack={setGroupByTrack}
          />
        </Col>
        <Col className="col-lg-9">
          <Row>
            <Col>
              <h1>Каталог</h1>
              <Row>
                <Col>
                  <CatalogSorting
                    sorting={sorting}
                    setSortingValue={setSortingValue}
                  />
                </Col>
                <Col>
                  <SearchBar
                    placeholder="Поиск по лекциям..."
                    defaultValue={filters.title?.value ?? ""}
                    onSearch={(value) => setFilterValue("title", value)}
                  />
                </Col>
              </Row>
              {loading ? (
                <Loading />
              ) : visibleList ? (
                <CatalogList
                  list={visibleList}
                  params={params}
                  setParam={setParam}
                />
              ) : (
                <div>Ничего не найдено</div>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};
