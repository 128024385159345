import React, { useState } from "react";
import PropTypes from "prop-types";
import $ from "jquery";
import {
  Modal,
  Alert,
  Button,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { Loading } from "../../_components/Loading";
import { useAuthContext } from "../../_services/authentication.service";

const AdminDelete = ({
  deleteUrl,
  handleDeleted,
  handleCancel,
  header,
  body,
}) => {
  const { requestBackend } = useAuthContext();
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState(false);

  function handleDelete() {
    setSubmitting(true);
    $.when(
      requestBackend({
        url: deleteUrl,
        type: "DELETE",
      })
    )
      .catch(() => {
        setSubmitting(false);
        setError("Произошла ошибка при удалении");
      })
      .then(() => {
        setSubmitting(false);
        handleDeleted();
      });
  }

  return (
    <Modal isOpen={true} toggle={handleCancel} centered size="lg">
      {header && <ModalHeader toggle={handleCancel}>{header}</ModalHeader>}
      <ModalBody>{body}</ModalBody>
      <ModalFooter>
        <Button
          color="danger"
          onClick={handleDelete}
          disabled={submitting || error}
        >
          Удалить
        </Button>
        <Button
          color="secondary"
          outline
          onClick={handleCancel}
          disabled={submitting}
        >
          Отменить
        </Button>
        {submitting && <Loading />}
        {error && (
          <div>
            <Alert color="danger">{error}</Alert>
          </div>
        )}
      </ModalFooter>
    </Modal>
  );
};

AdminDelete.propTypes = {
  show: PropTypes.bool,
  deleteUrl: PropTypes.string,
  handleDeleted: PropTypes.func,
  handleCancel: PropTypes.func,
  header: PropTypes.string,
  body: PropTypes.node,
};

export default AdminDelete;
