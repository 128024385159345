import React from "react";
import PropTypes from "prop-types";
import $ from "jquery";
import * as Yup from "yup";
import JoditEditor from "jodit-react";
import { useAuthContext } from "../../_services/authentication.service";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { Spinner } from "reactstrap";
import { useHistory } from "react-router-dom";
import { SelectFile } from "../../_components/UploadFile";

import "./AdminForm.css";
import SelectMany from "../../_components/SelectMany";

const AdminForm = ({
  submitUrl,
  submitMethod,
  fields,
  initialValues,
  redirectTo,
}) => {
  const { requestBackend } = useAuthContext();
  const history = useHistory();

  function handleSubmit(values, setSubmitting, setStatus) {
    setSubmitting(true);
    setStatus(undefined);
    const postAsForm = fields.some((f) => f.inputType === "file");
    const formData = new FormData();
    const jsonData = {};
    //console.log(values);
    for (const field of fields) {
      if (postAsForm) {
        if (field.inputType === "file") {
          if (values[field.name + "File"]) {
            formData.append(
              field.name,
              values[field.name + "File"],
              values[field.name + "File"].name
            );
          } else {
            formData.append(field.name, null);
          }
          formData.append(
            field.name + "Delete",
            values[field.name + "Delete"] || false
          );
        } else if (field.inputType === "selectMany") {
          const arr = field.postAs
            ? field.postAs(values[field.name])
            : values[field.name] === undefined || values[field.name] === null
            ? []
            : values[field.name];
          for (const key in arr) {
            formData.append(field.name + `[${key}]`, arr[key]);
          }
        } else {
          formData.append(
            field.name,
            field.postAs
              ? field.postAs(values[field.name])
              : field.inputType === "boolean"
              ? !!values[field.name]
              : values[field.name] === undefined || values[field.name] === null
              ? ""
              : values[field.name]
          );
        }
      } else {
        jsonData[field.name] = field.postAs
          ? field.postAs(values[field.name])
          : values[field.name];
      }
    }
    $.when(
      requestBackend({
        url: submitUrl,
        type: submitMethod || "POST",
        data: postAsForm ? formData : JSON.stringify(jsonData),
        processData: false,
        contentType: false,
        headers: postAsForm
          ? undefined
          : {
              "Content-Type": "application/json",
            },
      })
    )
      .then(() => {
        setSubmitting(false);
        history.push(redirectTo);
      })
      .catch(() => {
        setSubmitting(false);
        setStatus("Произошла ошибка при загрузке данных");
      });
  }
  return (
    <div>
      <Formik
        initialValues={
          initialValues ||
          Object.fromEntries(fields.map((f) => [f.name, f.initialValue]))
        }
        validationSchema={Yup.object().shape(
          Object.fromEntries(fields.map((f) => [f.name, f.validation]))
        )}
        onSubmit={(values, formikHelpers) =>
          handleSubmit(
            values,
            formikHelpers.setSubmitting,
            formikHelpers.setStatus
          )
        }
      >
        {({ errors, status, touched, isSubmitting, isValid }) => (
          <Form>
            {fields
              .filter((f) => f.inputType !== "none")
              .map((field) => (
                <div className="form-group" key={"form-group-" + field.name}>
                  <label htmlFor="lastname">{field.label}</label>
                  <Field
                    name={field.name}
                    className={
                      "form-control" +
                      (errors.fullname && touched.fullname ? " is-invalid" : "")
                    }
                  >
                    {({ field: { value }, form: { setFieldValue } }) =>
                      field.inputType === "richtext" ? (
                        <JoditEditor
                          value={value}
                          onBlur={(value) => setFieldValue(field.name, value)}
                        />
                      ) : field.inputType === "file" ? (
                        <SelectFile
                          accept={field.acceptFileTypes}
                          prevFileId={value?.id}
                          prevFileName={value?.fileName}
                          onChange={(file) => {
                            if (file === null) {
                              setFieldValue(field.name + "Delete", true);
                            } else {
                              setFieldValue(field.name + "Delete", false);
                            }
                            setFieldValue(field.name + "File", file);
                          }}
                        />
                      ) : field.inputType === "select" && field.options ? (
                        <select
                          className="form-control form-control-sm"
                          onChange={(e) =>
                            setFieldValue(field.name, +e.target.value || 0)
                          }
                          value={value}
                        >
                          <option></option>
                          {field.options.map((o) => (
                            <option
                              key={field.name + "-option-" + (o.id || o)}
                              value={o.id || o}
                            >
                              {o.label || o}
                            </option>
                          ))}
                          ;
                        </select>
                      ) : field.inputType === "selectMany" && field.options ? (
                        <SelectMany
                          labelSelected={field.labelSelected}
                          labelUnelected={field.labelUnselected}
                          all={field.options || []}
                          selected={value || []}
                          onChange={(value) => setFieldValue(field.name, value)}
                        />
                      ) : field.inputType === "boolean" ? (
                        <div className="form-check">
                          <input
                            className={
                              "form-check-input" +
                              (errors[field.name] && touched[field.name]
                                ? " is-invalid"
                                : "")
                            }
                            type="checkbox"
                            checked={value}
                            onChange={(e) =>
                              setFieldValue(field.name, e.target.checked)
                            }
                          />
                          <label></label>
                        </div>
                      ) : (
                        <input
                          className="form-control"
                          type={field.inputType}
                          value={value}
                          onChange={(e) =>
                            setFieldValue(
                              field.name,
                              field.inputType === "numeric"
                                ? +e.target.value
                                : e.target.value
                            )
                          }
                        />
                      )
                    }
                  </Field>
                  <ErrorMessage
                    name={field.name}
                    component="div"
                    className="error-message"
                  />
                </div>
              ))}
            {initialValues?.updated && (
              <div className="text-right">
                <em>
                  Последнее обновление: {initialValues.updated.toLocaleString()}
                </em>
              </div>
            )}
            <div className="form-group">
              <button
                type="submit"
                className="btn btn-primary"
                disabled={isSubmitting}
              >
                Сохранить
              </button>
              <button
                type="reset"
                className="btn btn-light btn-outer-primary"
                disabled={isSubmitting}
                onClick={() => history.push(redirectTo)}
              >
                Отменить
              </button>
              {isSubmitting && <Spinner />}
            </div>
            {!isValid && (
              <div className="alert alert-danger">
                Проверьте правильность заполнения полей
              </div>
            )}
            {status && <div className="alert alert-danger">{status}</div>}
          </Form>
        )}
      </Formik>
    </div>
  );
};

AdminForm.propTypes = {
  submitUrl: PropTypes.string,
  submitMethod: PropTypes.string,
  fields: PropTypes.array,
  initialValues: PropTypes.object,
  redirectTo: PropTypes.string,
};

export default AdminForm;
