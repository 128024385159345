const server =
  (window.app && window.app.env.SERVER_URL) ||
  process.env.REACT_APP_SERVER_URL ||
  window.location;
const apiPath =
  (window.app && window.app.env.API_PATH) ||
  process.env.REACT_APP_API_PATH ||
  "/api";

const kurentoUrl =
  (window.app && window.app.env.KURENTO_URL) ||
  process.env.REACT_APP_KURENTO_URL ||
  "https://player.kurento.samsmu.ru/";

export { server, apiPath };

const apiUrl = new URL(apiPath.endsWith("/") ? apiPath : apiPath + "/", server);

export function getBackendApiUrl(path) {
  let url = new URL(path, apiUrl.href);
  return url.href;
}

export function getMediaUrl(mediaId) {
  if (!mediaId) return null;
  return `/media/${mediaId}`;
}

export function getKurentoUrl(code) {
  if (!code) return null;
  return `${kurentoUrl}?v=${code}`;
}
