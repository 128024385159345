import React from "react";
import Routes from "../../Routes";
import AdminForm from "../_Proto/AdminForm";
import * as Yup from "yup";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";

const AdminSpecialityCreate = () => {
  return (
    <>
      <BreadcrumbsItem to={Routes.AdminSpecialityCreate.route}>
        {Routes.AdminSpecialityCreate.title}
      </BreadcrumbsItem>
      <h1>Создание специальности</h1>
      <AdminForm
        submitUrl="admin/specialities"
        fields={[
          {
            name: "title",
            label: "Название специальности",
            validation: Yup.string().required(
              "Название специальности обязательно для заполнения"
            ),
            inputType: "text",
            acceptFileTypes: "",
          },
        ]}
        redirectTo={Routes.AdminSpecialities.route}
      />
    </>
  );
};

export default AdminSpecialityCreate;
