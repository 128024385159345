import React from "react";
import PropTypes from "prop-types";
import { VideoPlayer } from "../_components/VideoPlayer/VideoPlayer";
import { getKurentoUrl, getMediaUrl } from "../_helpers/backend.url";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

import "./VideoPopup.css";

const VideoPopup = ({ title, video, setVideo }) => {
  if (!video) return null;
  return (
    <div className="popup-container">
      <div className="popup-header">
        <h5>{title}</h5>
        <button className="popup-button-close" onClick={() => setVideo(null)}>
          <FontAwesomeIcon icon={faTimes} size="lg" />
        </button>
      </div>
      <div className="popup-main">
        <VideoPlayer
          size="maximised"
          previewUrl={getMediaUrl(video.previewId)}
          videoUrl={video.url || getKurentoUrl(video.code)}
        />
      </div>
    </div>
  );
};

VideoPopup.propTypes = {
  title: PropTypes.string,
  video: PropTypes.object,
  setVideo: PropTypes.func,
};

export default VideoPopup;
