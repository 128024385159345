import React from "react";
import Routes from "../../Routes";
import * as Yup from "yup";
import AdminUpdate from "../_Proto/AdminUpdate";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";

const AdminSpecialityUpdate = ({ match }) => {
  const id = match.params.id;
  return (
    <>
      <BreadcrumbsItem to={Routes.AdminSpecialityEdit.route}>
        {Routes.AdminSpecialityEdit.title}
      </BreadcrumbsItem>
      <h1>Редактирование специальности {match.params.id}</h1>
      <AdminUpdate
        loadUrl={`admin/specialities/${id}`}
        submitUrl="admin/specialities"
        fields={[
          {
            name: "title",
            label: "Название специальности",
            validation: Yup.string().required(
              "Название специальности обязательно для заполнения"
            ),

            inputType: "text",
            acceptFileTypes: "",
          },
        ]}
        redirectTo={Routes.AdminSpecialities.route}
      />
    </>
  );
};

export default AdminSpecialityUpdate;
