import React, { useState } from "react";
import { Input, Row, Col, Card, CardHeader, CardBody } from "reactstrap";
import "./UploadFile.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFile,
  faTimesCircle,
  faFilePdf,
  faFilePowerpoint,
  faTrash,
  faSyncAlt,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import { getMediaUrl } from "../_helpers/backend.url";

export const SelectFile = ({
  accept,
  prevFileUrl,
  prevFileId,
  prevFileName,
  onChange,
}) => {
  const [origFileUrl] = useState(
    prevFileUrl || (prevFileId && getMediaUrl(prevFileId))
  );
  const [reset, setReset] = useState(false);
  const [previewUrl, setPreviewUrl] = useState(origFileUrl);
  const [selectedFile, setSelectedFile] = useState(undefined);
  const [errorHint, setErrorHint] = useState("");

  function onSelectFile(e) {
    const newFile = e.target.files[0];

    if (!accept.split(",").some((type) => newFile.type.match(type))) {
      //console.log(newFile.type);
      setErrorHint(
        "Неподдерживаемый тип файла. Выберите другой файл для загрузки"
      );
      setReset(!reset);
      return false;
    }

    setSelectedFile(newFile);
    //console.log(e.target.files[0]);
    onChange(newFile);
    setErrorHint("");
    setPreviewUrl(URL.createObjectURL(newFile));
  }

  function onClear(e) {
    setSelectedFile(undefined);
    onChange(null);
    setErrorHint("");
    setPreviewUrl("");
  }

  function onReset() {
    setSelectedFile(undefined);
    onChange(undefined);
    setErrorHint("");
    setReset(!reset);
    setPreviewUrl(origFileUrl);
  }

  const fileName = selectedFile?.fileName || prevFileName || "";
  return (
    <Card>
      <CardHeader className="p-1">
        <button
          type="button"
          className={`btn mx-1 ${previewUrl ? "active" : "disabled"}`}
          onClick={onReset}
        >
          <FontAwesomeIcon icon={faSyncAlt} /> Вернуть
        </button>
        <button
          type="button"
          className={`btn mx-1 ${previewUrl ? "active" : "disabled"}`}
          onClick={onClear}
        >
          <FontAwesomeIcon icon={faTrash} /> Удалить
        </button>
      </CardHeader>
      <CardBody className="p-2">
        <Row>
          <Col className="col-12 col-md-3 text-center files-preview text-truncate">
            {previewUrl ? (
              accept.includes("image") ? (
                <img src={previewUrl} className="img-fluid img-thumbnail" />
              ) : fileName.endsWith("pdf") ? (
                <FontAwesomeIcon icon={faFilePdf} size="3x" />
              ) : fileName.endsWith("ppt") || fileName.endsWith("pptx") ? (
                <FontAwesomeIcon icon={faFilePowerpoint} size="3x" />
              ) : (
                <FontAwesomeIcon icon={faFile} size="3x" />
              )
            ) : (
              <FontAwesomeIcon icon={faTimesCircle} size="2x" />
            )}

            <div className="figure-caption text-wrap">
              {previewUrl ? (
                <a href={previewUrl}>
                  {(selectedFile && selectedFile.name) ||
                    fileName ||
                    (origFileUrl && origFileUrl.split("/").pop().split("?")[0])}
                </a>
              ) : (
                "Ничего не выбрано"
              )}
            </div>
          </Col>
          <Col className="files col-12 col-md-9">
            <Input
              type="file"
              key={reset}
              className="form-control"
              accept={accept}
              onChange={onSelectFile}
            />
            <FontAwesomeIcon icon={faUpload} size="2x" />
            <div className="text-danger p-1 files-error" role="alert">
              {errorHint}
            </div>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};
