import React from "react";
import Routes from "../../Routes";
import * as Yup from "yup";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import AdminUpdate from "../_Proto/AdminUpdate";

const AdminAuthorUpdate = ({ match }) => {
  const id = match.params.id;
  return (
    <>
      <BreadcrumbsItem to={Routes.AdminAuthorEdit.route}>
        {Routes.AdminAuthorEdit.title}
      </BreadcrumbsItem>
      <h1>Редактирование автора {match.params.id}</h1>
      <AdminUpdate
        loadUrl={`admin/authors/${id}`}
        submitUrl="admin/authors"
        fields={[
          {
            name: "id",
            label: "id",
            inputType: "none",
          },
          {
            name: "familyName",
            label: "Фамилия",
            validation: Yup.string()
              .nullable()
              .required("Фамилия автора обязательна для заполнения"),
            inputType: "text",
            acceptFileTypes: "",
          },
          {
            name: "personalName",
            label: "Имя",
            validation: Yup.string()
              .nullable()
              .required("Имя автора обязательно для заполнения"),
            inputType: "text",
            acceptFileTypes: "",
          },
          {
            name: "patronymic",
            label: "Отчество",
            inputType: "text",
            acceptFileTypes: "",
          },
          {
            name: "description",
            label:
              "Описание автора (ученая степень, ученое звание и другая информация)",
            inputType: "richtext",
            acceptFileTypes: "",
          },
          {
            name: "foto",
            label: "Изображение профиля",
            inputType: "file",
            acceptFileTypes: "image/*,image/png,image/jpeg,image/gif",
          },
        ]}
        redirectTo={Routes.AdminAuthors.route}
      />
    </>
  );
};

export default AdminAuthorUpdate;
