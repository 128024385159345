import React from "react";
import { DownloadPresentation } from "../_components/DownloadPresentation";
import { VideoPlayer } from "../_components/VideoPlayer/VideoPlayer";
import { MediacontentItem } from "../_components/MediacontentItem";
import { getKurentoUrl, getMediaUrl } from "../_helpers/backend.url";

export const Mediacontent = ({ presentations, videos }) => (
  <div>
    <h4>Медиаконтент</h4>
    <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
      {Array.isArray(videos) &&
        videos.length > 0 &&
        videos.map(
          (v) =>
            v?.url && (
              <MediacontentItem
                key={v.id || v.url}
                title={v.title || "Видеоролик"}
                maximised={v.maximised}
              >
                <VideoPlayer
                  previewUrl={getMediaUrl(v.previewId)}
                  videoUrl={v.url || getKurentoUrl(v.code)}
                  size={v.maximised ? "maximised" : ""}
                />
              </MediacontentItem>
            )
        )}
      {Array.isArray(presentations) &&
        presentations.length > 0 &&
        presentations.map(
          (p) =>
            p?.id && (
              <MediacontentItem key={p?.id} title={p.title || "Презентация"}>
                <DownloadPresentation
                  previewImageUrl={getMediaUrl(p.previewId)}
                  presentationUrl={getMediaUrl(p.id)}
                />
              </MediacontentItem>
            )
        )}
      {!presentations?.filter((p) => !!p?.id)?.length &&
        !videos?.filter((v) => !!v?.code)?.length && (
          <p>Медиаконтент отсутствует</p>
        )}
    </div>
  </div>
);
