import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import $ from "jquery";
import AdminForm from "../_Proto/AdminForm";
import { useAuthContext } from "../../_services/authentication.service";
import { Loading } from "../../_components/Loading";
import { Alert } from "reactstrap";

const AdminUpdate = ({ loadUrl, submitUrl, fields, redirectTo }) => {
  const { requestBackend } = useAuthContext();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    setLoading(true);
    setError(false);
    $.when(
      requestBackend({
        url: loadUrl,
        onSuccess: (data) => {
          for (const field in data) {
            data[field] = data[field] || undefined;
          }
          setData({
            ...data,
            created: new Date(data.created),
            updated: new Date(data.updated),
          });
        },
      })
    )
      .catch(() => {
        setLoading(false);
        setError("Произошла ошибка при загрузке данных");
      })
      .then(() => {
        setLoading(false);
      });
  }, [loadUrl]);

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <Alert color="danger">{error}</Alert>;
  }

  return (
    <AdminForm
      submitUrl={submitUrl}
      submitMethod="PUT"
      fields={fields.map((f) => ({
        initialValue: f.initialMap
          ? f.initialMap(data[f.initialField || f.name])
          : data[f.initialField || f.name],
        ...f,
      }))}
      redirectTo={redirectTo}
    />
  );
};

AdminUpdate.propTypes = {
  loadUrl: PropTypes.string,
  submitUrl: PropTypes.string,
  fields: PropTypes.array,
  redirectTo: PropTypes.string,
};

export default AdminUpdate;
