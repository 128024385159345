import React from "react";
import { FormGroup } from "reactstrap";

import "./Filter.css";

export const FilterSelect = ({ label, options, selectedOption, onFilter }) => {
  const onOptionChange = (e) => {
    onFilter(e.target.value);
  };

  return (
    <FormGroup>
      <label className="text-label" style={{ maxWidth: "110px" }}>
        {label}
      </label>
      <select
        className="form-control form-control-sm"
        onChange={onOptionChange}
        value={selectedOption}
      >
        <option></option>
        {Array.isArray(options) &&
          options.map((option, i) => (
            <option
              key={i}
              value={option.id}
              disabled={option.catalogItemsCount === 0}
            >
              {option.title}
            </option>
          ))}
        ;
      </select>
    </FormGroup>
  );
};

export function GetFilterOptions(data, optionName) {
  return (
    data &&
    data
      .map((item) => item[optionName])
      .filter((val, i, self) => val && self.indexOf(val) === i)
  );
}
