import React from "react";
import PropTypes from "prop-types";
import { Nav, NavbarText } from "reactstrap";
import { SortingOption } from "../_components/SortingOption";

const CatalogSorting = ({ sorting, setSortingValue }) => {
  return (
    <Nav className="nav-pills">
      <NavbarText className="mx-1 text-label">Сортировать по</NavbarText>
      <SortingOption
        text="Стоимости"
        active={sorting.field === "price"}
        descending={sorting.field === "price" && sorting.descending}
        onSort={() => setSortingValue("price")}
      />
      <SortingOption
        text="Названию"
        active={sorting.field === "title"}
        descending={sorting.field === "title" && sorting.descending}
        onSort={() => setSortingValue("title")}
      />
    </Nav>
  );
};

CatalogSorting.propTypes = {
  sorting: PropTypes.object,
  setSortingValue: PropTypes.func,
};

export default CatalogSorting;
