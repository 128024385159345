import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";
import Routes from "../../Routes";
import AdminDataList from "../_Proto/AdminDataList";

const AdminDepartments = () => {
  return (
    <>
      <h1>Справочник кафедр</h1>
      <div className="my-3">
        <Link to={Routes.AdminDepartmentCreate.route}>
          <Button color="primary">
            {Routes.AdminDepartmentCreate.title}{" "}
            <FontAwesomeIcon icon={faPlus} />
          </Button>
        </Link>
      </div>
      <AdminDataList
        url="admin/departments"
        fields={[
          { name: "id", title: "ID", dataType: "string" },
          { name: "title", title: "Название кафедры", dataType: "string" },
        ]}
        editRoute={Routes.AdminDepartmentEdit.route}
        deleteUrl="admin/departments"
        deleteRowTitle={(d) => `кафедру "${d?.title}"`}
      />
    </>
  );
};

export default AdminDepartments;
