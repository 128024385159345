import React from "react";
import Routes from "../../Routes";
import AdminForm from "../_Proto/AdminForm";
import * as Yup from "yup";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";

const AdminAuthorCreate = () => {
  return (
    <>
      <BreadcrumbsItem to={Routes.AdminAuthorCreate.route}>
        {Routes.AdminAuthorCreate.title}
      </BreadcrumbsItem>
      <h1>Добавление автора</h1>
      <AdminForm
        submitUrl="admin/authors"
        fields={[
          {
            name: "FamilyName",
            label: "Фамилия",
            validation: Yup.string()
              .nullable()
              .required("Фамилия автора обязательна для заполнения"),
            inputType: "text",
          },
          {
            name: "PersonalName",
            label: "Имя",
            validation: Yup.string()
              .nullable()
              .required("Имя автора обязательно для заполнения"),
            inputType: "text",
          },
          {
            name: "Patronymic",
            label: "Отчество",
            inputType: "text",
          },
          {
            name: "Description",
            label:
              "Описание автора (ученая степень, ученое звание и другая информация)",
            inputType: "richtext",
          },
          {
            name: "Foto",
            label: "Изображение профиля",
            inputType: "file",
            acceptFileTypes: "image/*,image/png,image/jpeg,image/gif",
          },
        ]}
        redirectTo={Routes.AdminAuthors.route}
      />
    </>
  );
};

export default AdminAuthorCreate;
