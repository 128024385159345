import React from "react";
import Routes from "../../Routes";
import AdminForm from "../_Proto/AdminForm";
import * as Yup from "yup";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";

const AdminDepartmentCreate = () => {
  return (
    <>
      <BreadcrumbsItem to={Routes.AdminDepartmentCreate.route}>
        {Routes.AdminDepartmentCreate.title}
      </BreadcrumbsItem>
      <h1>Создание кафедры</h1>
      <AdminForm
        submitUrl="admin/departments"
        fields={[
          {
            name: "title",
            label: "Название кафедры",
            validation: Yup.string().required(
              "Название кафедры обязательно для заполнения"
            ),
            inputType: "text",
            acceptFileTypes: "",
          },
        ]}
        redirectTo={Routes.AdminDepartments.route}
      />
    </>
  );
};

export default AdminDepartmentCreate;
