export function doFilterSort(data, filters, sorting) {
  if (!data || !data.filter) return [];
  let filteredData = data.filter((item) =>
    Object.keys(filters).every(
      (field) =>
        !filters[field].value ||
        (typeof item[field] === "string" &&
          item[field]
            .toLocaleLowerCase()
            .includes(filters[field].value.toLocaleLowerCase())) ||
        (item[field]?.id && item[field].id == filters[field].value)
    )
  );

  if (sorting) {
    filteredData.sort(
      (left, right) =>
        left[sorting.field] !== undefined &&
        right[sorting.field] !== undefined &&
        (typeof left[sorting.field] === "string"
          ? left[sorting.field].localeCompare(right[sorting.field])
          : left[sorting.field] - right[sorting.field]) *
          (sorting.descending ? -1 : 1)
    );
  }

  return filteredData;
}
