import React, { useEffect, useState } from "react";
import $ from "jquery";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { Alert } from "reactstrap";
import { Loading } from "../../_components/Loading";
import { useAuthContext } from "../../_services/authentication.service";
import AdminTable from "../_Table/AdminTable";
import AdminDelete from "./AdminDelete";

const AdminDataList = ({
  url,
  fields,
  editRoute,
  deleteUrl,
  deleteRowTitle,
}) => {
  const history = useHistory();
  const { requestBackend } = useAuthContext();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [data, setData] = useState([]);
  const [rowToDelete, setRowToDelete] = useState(undefined);

  function editRow(row) {
    history.push(editRoute.replace(":id", row.id));
  }

  useEffect(() => {
    if (!loading) return;
    setError(false);
    $.when(
      requestBackend({
        url: url,
        onSuccess: (data) => {
          setData(
            data.map((l) => ({
              ...l,
              created: new Date(l.created),
              updated: new Date(l.updated),
            }))
          );
        },
      })
    )
      .catch(() => {
        setLoading(false);
        setError("Произошла ошибка при загрузке данных");
      })
      .then(() => {
        setLoading(false);
      });
  }, [loading]);

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <Alert color="danger">{error}</Alert>;
  }

  return (
    <>
      <AdminTable
        data={data}
        fields={fields}
        editRow={editRoute ? editRow : undefined}
        deleteRow={deleteUrl ? (row) => setRowToDelete(row) : undefined}
      />
      {rowToDelete && (
        <AdminDelete
          deleteUrl={`${deleteUrl}/${rowToDelete?.id}`}
          handleDeleted={() => {
            setLoading(true);
            setRowToDelete(undefined);
          }}
          handleCancel={() => setRowToDelete(undefined)}
          header="Подтверждение удаления"
          body={`Вы уверены, что хотите удалить ${deleteRowTitle(
            rowToDelete
          )}? Данную операцию невозможно отменить`}
        />
      )}
    </>
  );
};

AdminDataList.propTypes = {
  fields: PropTypes.array,
  url: PropTypes.string,
  editRoute: PropTypes.string,
  deleteUrl: PropTypes.string,
  deleteRowTitle: PropTypes.func,
};

export default AdminDataList;
