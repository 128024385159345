import React from "react";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { Switch } from "react-router-dom";
import Routes from "../Routes";
import { PrivateRoute } from "../_components/PrivateRoute";
import { Role } from "../_helpers/role";
import AdminAuthors from "./Authors/AdminAuthors";
import AdminAuthorCreate from "./Authors/AdminAuthorCreate";
import AdminAuthorUpdate from "./Authors/AdminAuthorUpdate";
import AdminDepartmentCreate from "./Departments/AdminDepartmentCreate";
import AdminDepartments from "./Departments/AdminDepartments";
import AdminDepartmentUpdate from "./Departments/AdminDepartmentUpdate";
import AdminLectures from "./Lectures/AdminLectures";
import AdminSectionCreate from "./Sections/AdminSectionCreate";
import AdminSections from "./Sections/AdminSections";
import AdminSectionUpdate from "./Sections/AdminSectionUpdate";
import AdminSpecialities from "./Specialities/AdminSpecialities";
import AdminSpecialityCreate from "./Specialities/AdminSpecialityCreate";
import AdminSpecialityUpdate from "./Specialities/AdminSpecialityUpdate";
import AdminLectureCreate from "./Lectures/AdminLectureCreate";
import AdminLectureUpdate from "./Lectures/AdminLectureUpdate";
import AdminTracks from "./Tracks/AdminTracks";
import AdminTrackUpdate from "./Tracks/AdminTrackUpdate";
import AdminTrackCreate from "./Tracks/AdminTrackCreate";

export const AdminLecturesPage = () => (
  <>
    <BreadcrumbsItem to={Routes.AdminLectures.route}>
      {Routes.AdminLectures.title}
    </BreadcrumbsItem>
    <Switch>
      <PrivateRoute
        roles={[Role.Admin, Role.Manager]}
        path={Routes.AdminLectureCreate.route}
        component={AdminLectureCreate}
      />
      <PrivateRoute
        roles={[Role.Admin, Role.Manager]}
        path={Routes.AdminLectureEdit.route}
        component={AdminLectureUpdate}
      />
      <PrivateRoute
        roles={[Role.Admin, Role.Manager]}
        component={AdminLectures}
      />
    </Switch>
  </>
);

export const AdminTracksPage = () => (
  <>
    <BreadcrumbsItem to={Routes.AdminTracks.route}>
      {Routes.AdminTracks.title}
    </BreadcrumbsItem>
    <Switch>
      <PrivateRoute
        roles={[Role.Admin, Role.Manager]}
        path={Routes.AdminTrackCreate.route}
        component={AdminTrackCreate}
      />
      <PrivateRoute
        roles={[Role.Admin, Role.Manager]}
        path={Routes.AdminTrackEdit.route}
        component={AdminTrackUpdate}
      />
      <PrivateRoute
        roles={[Role.Admin, Role.Manager]}
        component={AdminTracks}
      />
    </Switch>
  </>
);

export const AdminAuthorsPage = () => (
  <>
    <BreadcrumbsItem to={Routes.AdminAuthors.route}>
      {Routes.AdminAuthors.title}
    </BreadcrumbsItem>
    <Switch>
      <PrivateRoute
        roles={[Role.Admin, Role.Manager]}
        path={Routes.AdminAuthorCreate.route}
        component={AdminAuthorCreate}
      />
      <PrivateRoute
        roles={[Role.Admin, Role.Manager]}
        path={Routes.AdminAuthorEdit.route}
        component={AdminAuthorUpdate}
      />
      <PrivateRoute
        roles={[Role.Admin, Role.Manager]}
        path={Routes.AdminAuthors.route}
        component={AdminAuthors}
      />
    </Switch>
  </>
);

export const AdminDepartmentsPage = () => (
  <>
    <BreadcrumbsItem to={Routes.AdminDepartments.route}>
      {Routes.AdminDepartments.title}
    </BreadcrumbsItem>
    <Switch>
      <PrivateRoute
        roles={[Role.Admin, Role.Manager]}
        path={Routes.AdminDepartmentEdit.route}
        component={AdminDepartmentUpdate}
      />
      <PrivateRoute
        roles={[Role.Admin, Role.Manager]}
        path={Routes.AdminDepartmentCreate.route}
        component={AdminDepartmentCreate}
      />
      <PrivateRoute
        roles={[Role.Admin, Role.Manager]}
        path={Routes.AdminDepartments.route}
        component={AdminDepartments}
      />
    </Switch>
  </>
);

export const AdminSpecialitiesPage = () => (
  <>
    <BreadcrumbsItem to={Routes.AdminSpecialities.route}>
      {Routes.AdminSpecialities.title}
    </BreadcrumbsItem>
    <Switch>
      <PrivateRoute
        roles={[Role.Admin, Role.Manager]}
        path={Routes.AdminSpecialityEdit.route}
        component={AdminSpecialityUpdate}
      />
      <PrivateRoute
        roles={[Role.Admin, Role.Manager]}
        path={Routes.AdminSpecialityCreate.route}
        component={AdminSpecialityCreate}
      />
      <PrivateRoute
        roles={[Role.Admin, Role.Manager]}
        path={Routes.AdminSpecialities.route}
        component={AdminSpecialities}
      />
    </Switch>
  </>
);

export const AdminSectionsPage = () => (
  <>
    <BreadcrumbsItem to={Routes.AdminSections.route}>
      {Routes.AdminSections.title}
    </BreadcrumbsItem>
    <Switch>
      <PrivateRoute
        roles={[Role.Admin, Role.Manager]}
        path={Routes.AdminSectionEdit.route}
        component={AdminSectionUpdate}
      />
      <PrivateRoute
        roles={[Role.Admin, Role.Manager]}
        path={Routes.AdminSectionCreate.route}
        component={AdminSectionCreate}
      />
      <PrivateRoute
        roles={[Role.Admin, Role.Manager]}
        path={Routes.AdminSections.route}
        component={AdminSections}
      />
    </Switch>
  </>
);

export const AdminPage = () => (
  <>
    <BreadcrumbsItem to={Routes.Admin.route}>
      {Routes.Admin.title}
    </BreadcrumbsItem>
    <div className="m-4">
      <Switch>
        <PrivateRoute
          roles={[Role.Admin, Role.Manager]}
          path={Routes.AdminLectures.route}
          component={AdminLecturesPage}
        />
        <PrivateRoute
          roles={[Role.Admin, Role.Manager]}
          path={Routes.AdminTracks.route}
          component={AdminTracksPage}
        />
        <PrivateRoute
          roles={[Role.Admin, Role.Manager]}
          path={Routes.AdminAuthors.route}
          component={AdminAuthorsPage}
        />
        <PrivateRoute
          roles={[Role.Admin, Role.Manager]}
          path={Routes.AdminDepartments.route}
          component={AdminDepartmentsPage}
        />
        <PrivateRoute
          roles={[Role.Admin, Role.Manager]}
          path={Routes.AdminSpecialities.route}
          component={AdminSpecialitiesPage}
        />
        <PrivateRoute
          roles={[Role.Admin, Role.Manager]}
          path={Routes.AdminSections.route}
          component={AdminSectionsPage}
        />
        <PrivateRoute
          roles={[Role.Admin, Role.Manager]}
          component={AdminLectures}
        />
      </Switch>
    </div>
  </>
);
