import React from "react";
import Routes from "../../Routes";
import * as Yup from "yup";
import AdminUpdate from "../_Proto/AdminUpdate";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";

const AdminDepartmentUpdate = ({ match }) => {
  const id = match.params.id;
  return (
    <>
      <BreadcrumbsItem to={Routes.AdminDepartmentEdit.route}>
        {Routes.AdminDepartmentEdit.title}
      </BreadcrumbsItem>
      <h1>Редактирование кафедры {match.params.id}</h1>
      <AdminUpdate
        loadUrl={`admin/departments/${id}`}
        submitUrl="admin/departments"
        fields={[
          {
            name: "title",
            label: "Название кафедры",
            validation: Yup.string().required(
              "Название кафедры обязательно для заполнения"
            ),

            inputType: "text",
            acceptFileTypes: "",
          },
        ]}
        redirectTo={Routes.AdminDepartments.route}
      />
    </>
  );
};

export default AdminDepartmentUpdate;
