import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Loading } from "../_components/Loading";
import { BuyTrack } from "./Buy";
import Routes from "../Routes";
import { TrackDetails } from "./TrackDetails";
import { useAuthContext } from "../_services/authentication.service";

import "./TrackPage.css";

export const TrackPage = ({ match, location }) => {
  const id = match.params.id;
  const action = match.params.action;
  const { user, requestBackend } = useAuthContext();
  const [track, setTrack] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    requestBackend({
      url: `catalog/track/${id}`,
      onSuccess: (data) => {
        setTrack(data);
        setLoading(false);
      },
    }).catch((err) => {
      console.error(err);
      setLoading(false);
    });
  }, [id, user, requestBackend]);

  return (
    <div style={{ marginTop: "1.5rem" }}>
      {loading ? (
        <Loading />
      ) : !track ? (
        <div>
          Трек не найден{" "}
          <Link to={Routes.Catalog.route}>Вернуться в каталог</Link>
        </div>
      ) : (
        <>
          {action === "buy" ? (
            <BuyTrack track={track} location={location} />
          ) : (
            <TrackDetails track={track} />
          )}
        </>
      )}
    </div>
  );
};
