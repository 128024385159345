import React from "react";
import { Catalog } from "../Catalog/Catalog";

export const AccountCatalog = () => {
  return (
    <Catalog
      enrolledOnly={true}
    />
  );
};
