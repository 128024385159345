import React, { useState } from "react";
import $ from "jquery";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { useAuthContext } from "../_services/authentication.service";
import { Input, Button } from "reactstrap";
import { Link, Redirect } from "react-router-dom";

const AccountPersonalField = ({ label, name, value, setValue }) => (
  <div className="my-3">
    <label htmlFor={name}>{label}</label>
    <Input
      type="text"
      name={name}
      value={value}
      onChange={(e) => setValue(e.target.value)}
    />
  </div>
);

export const AccountPersonalInfo = () => {
  const { user, updateUser, requestBackend, createAccountUrlSso } =
    useAuthContext();
  //const user = getUser();

  let nameParts = user.fullName.split(" ");
  const [firstname, setFirstname] = useState(nameParts[0]);
  const [lastname, setLastname] = useState(nameParts[nameParts.length - 1]);
  const [middlename, setMiddlename] = useState(
    nameParts.length === 3 ? nameParts[1] : ""
  );
  const [email, setEmail] = useState(user.email);

  const updateUserProfile = () => {
    requestBackend({
      url: `users/${user.id}`,
      data: {
        firstname: firstname,
        middlename: middlename,
        lastname: lastname,
        email: email,
      },
      method: "POST",
      success: (updatedUser) => {
        updateUser(updatedUser);
      },
    });
  };

  if (createAccountUrlSso) {
    window.location.href = createAccountUrlSso();
  }

  return (
    <div className="col-sm-6">
      <BreadcrumbsItem to="/account">Настройки пользователя</BreadcrumbsItem>
      <AccountPersonalField
        label="Имя"
        name="firstname"
        value={firstname}
        setValue={setFirstname}
      />
      <AccountPersonalField
        label="Отчество"
        name="middlename"
        value={middlename}
        setValue={setMiddlename}
      />
      <AccountPersonalField
        label="Фамилия"
        name="lastname"
        value={lastname}
        setValue={setLastname}
      />
      <AccountPersonalField
        label="Электронная почта"
        name="email"
        value={email}
        setValue={setEmail}
      />
      <Button className="btn-primary mx-1 my-3" onClick={updateUserProfile}>
        Сохранить
      </Button>

      {/* <Button className="btn-primary mx-1 my-3" onClick={manageAccountSso}>Изменить в SSO</Button> */}
    </div>
  );
};
