import React from "react";
import { Card } from "reactstrap";
import { Link } from "react-router-dom";
import { EnrollerdUsersCountCard } from "./EnrolledUsersCount";
import { LectureActionLink, TrackActionLink } from "./CatalogActions";
import { MarkupedText } from "../_components/MarkupedText";
import { AuthorsInfoShort } from "./AuthorInfo";
import Routes, { MediaRoutes } from "../Routes";
import { ImagePlugged } from "../_components/ImagePlugged";

import "./CatalogCard.css";
import { getMediaUrl } from "../_helpers/backend.url";

export const TrackCard = ({ track }) => {
  const trackRoute = Routes.getActual("Track", track.id, track.title);
  return (
    <Card className="trackcard">
      <div className="trackcard-header">
        <Link to={trackRoute.route}>
          <h2>{track.title}</h2>
        </Link>
        <TrackActionLink track={track} />
      </div>
      <div>
        {track.lectures &&
          track.lectures.map((lecture) => (
            <LectureCard
              key={"lectureCard-" + track.id + "-" + -lecture.id}
              lecture={lecture}
              injected={true}
            />
          ))}
      </div>
    </Card>
  );
};

export const LectureCard = ({ lecture, injected = false }) => {
  const lectureRoute = Routes.getActual("Lecture", lecture.id, lecture.title);
  return (
    <Card className={"catalogcard" + (injected ? " injected" : "")}>
      <div className="catalogcard-aside">
        <ImagePlugged
          src={getMediaUrl(lecture.imageId)}
          plugSrc={MediaRoutes.DefultLecturePoster}
          className="catalogcard-img"
          alt=""
        />
        <div style={{ width: "120px" }}>
          <EnrollerdUsersCountCard count={lecture.enrolledCount} />
        </div>
      </div>
      <div className="catalogcard-main">
        <Link to={lectureRoute.route}>
          <h3>{lecture.title}</h3>
        </Link>
        {lecture.info && (
          <div className="my-2">
            <strong>Аннотация</strong>
            <MarkupedText description={lecture.info} />
          </div>
        )}
      </div>
      <div className="catalogcard-buy">
        {!lecture.enrolledByCurrentUser && (
          <div className="catalogcard-price">
            <div>Стоимость лекции {injected ? " отдельно" : ""}</div>
            <div className="catalogcard-price-value">
              {lecture.price.toLocaleString("ru-RU", {
                style: "currency",
                currency: "RUB",
                minimumFractionDigits: 0,
              })}
            </div>
          </div>
        )}
        <LectureActionLink lecture={lecture} />
      </div>
    </Card>
  );
};
