import React, { useEffect, useState } from "react";
import $ from "jquery";
import Routes from "../../Routes";
import AdminForm from "../_Proto/AdminForm";
import * as Yup from "yup";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { useAuthContext } from "../../_services/authentication.service";
import { Loading } from "../../_components/Loading";
import { Alert } from "reactstrap";
import { getMediaUrl } from "../../_helpers/backend.url";

const AdminLectureCreate = () => {
  const { requestBackend } = useAuthContext();
  const [departments, setDepartments] = useState([]);
  const [specialities, setSpecialities] = useState([]);
  const [sections, setSections] = useState([]);
  const [authors, setAuthors] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (!loading) return;
    setError(false);
    Promise.all([
      $.when(
        requestBackend({
          url: "admin/departments",
          onSuccess: (data) => setDepartments(data),
        })
      ).catch(() => {
        setLoading(false);
        setError("Произошла ошибка при загрузке списка кафедр");
      }),
      $.when(
        requestBackend({
          url: "admin/sections",
          onSuccess: (data) => setSections(data),
        })
      ).catch(() => {
        setLoading(false);
        setError("Произошла ошибка при загрузке списка разделов");
      }),
      $.when(
        requestBackend({
          url: "admin/specialities",
          onSuccess: (data) => setSpecialities(data),
        })
      ).catch(() => {
        setLoading(false);
        setError("Произошла ошибка при загрузке списка специальностей");
      }),
      $.when(
        requestBackend({
          url: "admin/authors",
          onSuccess: (data) => setAuthors(data),
        })
      ).catch(() => {
        setLoading(false);
        setError("Произошла ошибка при загрузке списка авторов");
      }),
    ]).then(() => {
      setLoading(false);
    });
  }, []);

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <Alert color="danger">{error}</Alert>;
  }

  return (
    <>
      <BreadcrumbsItem to={Routes.AdminLectureCreate.route}>
        {Routes.AdminLectureCreate.title}
      </BreadcrumbsItem>
      <h1>Создание лекции</h1>
      <AdminForm
        submitUrl="admin/lectures"
        fields={[
          {
            name: "title",
            label: "Название лекции",
            validation: Yup.string().required(
              "Название лекции обязательно для заполнения"
            ),
            inputType: "text",
          },
          {
            name: "onSale",
            label: "Выставить на продажу (показывать в каталоге)",
            inputType: "boolean",
            initialValue: false,
          },
          {
            name: "price",
            label: "Цена (руб.)",
            inputType: "numeric",
            validation: Yup.number().required("Укажите цену лекции"),
          },
          {
            name: "sectionId",
            label: "Раздел",
            inputType: "select",
            options: sections.map((s) => ({ id: s.id, label: s.title })),
          },
          {
            name: "departmentId",
            label: "Кафедра",
            inputType: "select",
            options: departments.map((d) => ({ id: d.id, label: d.title })),
          },
          {
            name: "specialityId",
            label: "Специальность",
            inputType: "select",
            options: specialities.map((s) => ({ id: s.id, label: s.title })),
          },
          {
            name: "annotation",
            label: "Аннотация (для карточки лекции)",
            inputType: "richtext",
          },
          {
            name: "catalogAnnotation",
            label: "Краткая аннотация (для каталога)",
            inputType: "richtext",
          },
          {
            name: "image",
            label: "Изображение в каталоге",
            inputType: "file",
            acceptFileTypes: "image/*,image/png,image/jpeg,image/gif",
          },
          {
            name: "promoPresentation",
            label: "Промо-презентация лекции в формате pdf/pptx",
            inputType: "file",
            acceptFileTypes:
              "application/pdf,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation",
          },
          {
            name: "promoPresentationPreview",
            label: "Изображение презентации (отображается на кнопке загрузки)",
            inputType: "file",
            acceptFileTypes: "image/*,image/png,image/jpeg,image/gif",
          },
          {
            name: "promoVideoUrl",
            label: "Промо-видео лекции (укажите полную ссылку на видео)",
            validation: Yup.string()
              .nullable()
              .url("Укажите полную ссылку на видео, начиная с http/https."),
          },
          {
            name: "promoVideoPreview",
            label: "Заставка промо-видео (отображается перед запуском видео)",
            inputType: "file",
            acceptFileTypes: "image/*,image/png,image/jpeg,image/gif",
          },
          {
            name: "videoUrl",
            label:
              "Основное видео лекции (доступно после покупки, укажите полную ссылку на видео)",
            validation: Yup.string()
              .nullable()
              .url("Укажите полную ссылку на видео, начиная с http/https."),
          },
          {
            name: "videoPreview",
            label: "Заставка основно видео (отображается перед запуском видео)",
            inputType: "file",
            acceptFileTypes: "image/*,image/png,image/jpeg,image/gif",
          },
          {
            name: "authorIds",
            label: "Авторы",
            labelSelected: "Выбранные авторы лекции",
            labelUnselected: "Доступные авторы",
            inputType: "selectMany",
            options: authors.map((a) => ({
              id: a.id,
              label:
                a.personalName +
                " " +
                (a.patronymic ? a.patronymic + " " : "") +
                a.familyName,
              imageUrl: getMediaUrl(a.fotoId),
            })),
            postAs: (authors) => authors?.map((a) => a.id) || [],
          },
        ]}
        redirectTo={Routes.AdminLectures.route}
      />
    </>
  );
};

export default AdminLectureCreate;
