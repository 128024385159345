import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";
import Routes from "../../Routes";
import AdminDataList from "../_Proto/AdminDataList";

const AdminSpecialities = () => {
  return (
    <>
      <h1>Справочник специальностей</h1>
      <div className="my-3">
        <Link to={Routes.AdminSpecialityCreate.route}>
          <Button color="primary">
            {Routes.AdminSpecialityCreate.title}{" "}
            <FontAwesomeIcon icon={faPlus} />
          </Button>
        </Link>
      </div>
      <AdminDataList
        url="admin/specialities"
        fields={[
          { name: "id", title: "ID", dataType: "string" },
          {
            name: "title",
            title: "Название специальности",
            dataType: "string",
          },
        ]}
        editRoute={Routes.AdminSpecialityEdit.route}
        deleteUrl="admin/specialities"
        deleteRowTitle={(d) => `специальность "${d?.title}"`}
      />
    </>
  );
};

export default AdminSpecialities;
