import React, { useState } from "react";
import { getRusNumbersEnding } from "../_helpers/FormatStrings";
import { Pagination } from "../_components/Pagination/Pagination";
import { CatalogItem } from "./CatalogItem";
import { QueryParams } from "./QueryParams";

const defaultItemsPerPage = 5;

export const CatalogList = ({ list, params, setParam }) => {
  const [itemsPerPage, setItemsPerPage] = useState(
    +params.get(QueryParams.itemsPerPage) || defaultItemsPerPage
  );
  const [currentPage, setCurrentPage] = useState(
    +params.get(QueryParams.page) || 0
  );

  function onPageChanged(currentPage, itemsPerPage) {
    setCurrentPage(currentPage);
    setItemsPerPage(itemsPerPage);
    setParam(QueryParams.page, currentPage);
    setParam(QueryParams.itemsPerPage, itemsPerPage);
  }

  const visibleCurrentPage =
    Math.min(currentPage, ~~(list.length / itemsPerPage)) || 0;
  const itemsOnPage =
    itemsPerPage > 0 && list
      ? list.slice(
          visibleCurrentPage * itemsPerPage,
          (visibleCurrentPage + 1) * itemsPerPage
        )
      : list;

  return (
    <Pagination
      itemsCount={list?.length}
      defaultItemsPerPage={itemsPerPage}
      defaultSelectedPage={currentPage}
      onPageChanged={onPageChanged}
    >
      <div className="text-label">
        Найдено {list?.length} лекц
        {getRusNumbersEnding(list?.length, "ия", "ии", "ий")} и трек
        {getRusNumbersEnding(list?.length, "", "а", "ов")}
      </div>
      {itemsOnPage && itemsOnPage.length > 0 ? (
        itemsOnPage.map((item) => (
          <CatalogItem key={`${item.type}-${item.id}`} item={item} />
        ))
      ) : (
        <em>"Лекции/треки, соответствующие запросу, не найдены"</em>
      )}
    </Pagination>
  );
};
