import React from "react";
import PropTypes from "prop-types";

const AdminTablePagination = ({
  page,
  setPage,
  pagesCount,
  pageSize,
  setPageSize,
}) => {
  if (pagesCount <= 0) return null;

  return (
    <div className="admin-pagination-container">
      <div className="admin-pagination-navigation">
        <span>Страница: </span>
        <select onChange={(e) => setPage(+e.target.value)} defaultValue={page}>
          {Array.from(Array(pagesCount).keys()).map((p) => (
            <option key={"page-" + p} value={p}>
              {p + 1}
            </option>
          ))}
        </select>
      </div>
      <div className="admin-pagination-pagesize">
        <span>На странице: </span>
        <select
          onChange={(e) => setPageSize(+e.target.value)}
          defaultValue={pageSize}
        >
          {[5, 10, 20, 50, 100].map((p) => (
            <option key={"page-" + p} value={p}>
              {p}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

AdminTablePagination.propTypes = {
  page: PropTypes.number,
  setPage: PropTypes.func,
  pagesCount: PropTypes.number,
  pageSize: PropTypes.number,
  setPageSize: PropTypes.func,
};

export default AdminTablePagination;
