import React from "react";
import { AccountPersonalInfo } from "./AccountPersonalInfo";
import { AccountCatalog } from "./AccountCatalog";
import { Switch } from "react-router-dom";
import { PrivateRoute } from "../_components/PrivateRoute";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import Routes from "../Routes";

export const AccountPage = () => {
  return (
    <div>
      <BreadcrumbsItem to={Routes.Account.route}>
        {Routes.Account.title}
      </BreadcrumbsItem>
      <div className="m-4">
        <Switch>
          <PrivateRoute
            path={Routes.AccountCatalog.route}
            component={AccountCatalog}
          />
          <PrivateRoute
            component={AccountPersonalInfo}
          />
        </Switch>
      </div>
    </div>
  );
};
