import React from "react";
import { LectureCard, TrackCard } from "./CatalogCard";

export const CatalogItem = ({ item }) => (
  <div className="mb-3">
    {item["lectures"] ? (
      <TrackCard track={item} />
    ) : (
      <LectureCard lecture={item} />
    )}
  </div>
);
