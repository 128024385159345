import React from "react";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { Row, Col } from "reactstrap";
import { EnrollerdUsersCount } from "./EnrolledUsersCount";
import { AuthorInfo } from "./AuthorInfo";
import { MarkupedText } from "../_components/MarkupedText";
import { LectureActionLink } from "./CatalogActions";
import Routes from "../Routes";
import { Mediacontent } from "../_components/Mediacontent";

export const LectureDetails = ({ lecture }) => {
  const thisRoute = Routes.getActual("Lecture", lecture.id, lecture.title);
  return (
    <div style={{ position: "relative" }}>
      <BreadcrumbsItem to={thisRoute.route}>{thisRoute.title}</BreadcrumbsItem>

      <h1>Лекция "{lecture.title}"</h1>

      <LectureActionLink
        lecture={lecture}
        showPrice={true}
        videoEmbedded={true}
      />
      <Row>
        <Col className="col-sm-8 col-12">
          <EnrollerdUsersCount count={lecture.enrolledCount} />
          <h4>Аннотация</h4>
          <MarkupedText description={lecture.annotation} />
        </Col>
        <Col className="col-sm-4 col-12">
          {lecture.authors &&
            lecture.authors.map((author) => (
              <AuthorInfo key={author.id} author={author} />
            ))}
        </Col>
      </Row>

      <Mediacontent
        presentations={[
          {
            id: lecture.promoPresentation?.id,
            previewId: lecture.promoPresentationPreview?.id,
          },
        ]}
        videos={[
          {
            id: lecture.promoVideo?.id,
            code: lecture.promoVideo?.code,
            url: lecture.promoVideo?.url,
            previewId: lecture.promoVideoPreview?.id,
            title: "Промо-ролик",
          },
        ]}
      />
    </div>
  );
};
