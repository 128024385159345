import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Loading } from "../_components/Loading";
import { useAuthContext } from "../_services/authentication.service";
import { BuyLecture } from "./Buy";
import Routes from "../Routes";
import { LectureDetails } from "./LectureDetails";

export const LecturePage = (props) => {
  const id = props.match.params.id;
  const action = props.match.params.action;
  const { user, requestBackend } = useAuthContext();
  const [lecture, setLecture] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    requestBackend({
      url: `catalog/lecture/${id}`,
      onSuccess: (data) => {
        setLecture(data);
        setLoading(false);
      },
    }).catch((err) => {
      console.error(err);
      setLoading(false);
    });
  }, [id, user, requestBackend]);

  return (
    <div style={{ marginTop: "1.5rem" }}>
      {loading ? (
        <Loading />
      ) : !lecture ? (
        <div>
          Лекция не найдена{" "}
          <Link to={Routes.Catalog.route}>Вернуться в каталог</Link>
        </div>
      ) : action === "buy" ? (
        <BuyLecture lecture={lecture} location={props.location} />
      ) : (
        <LectureDetails lecture={lecture} />
      )}
    </div>
  );
};
