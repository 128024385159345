import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link, useHistory } from "react-router-dom";
import { Button } from "reactstrap";
import Routes from "../../Routes";
import AdminDataList from "../_Proto/AdminDataList";

const AdminAuthors = () => {
  return (
    <>
      <h1>Управление авторами</h1>
      <div className="my-3">
        <Link to={Routes.AdminAuthorCreate.route}>
          <Button color="primary">
            {Routes.AdminAuthorCreate.title} <FontAwesomeIcon icon={faPlus} />
          </Button>
        </Link>
      </div>
      <AdminDataList
        url="admin/authors"
        fields={[
          { name: "id", title: "ID", dataType: "string" },
          { name: "familyName", title: "Фамилия", dataType: "string" },
          { name: "personalName", title: "Имя", dataType: "string" },
          { name: "patronymic", title: "Отчество", dataType: "string" },
        ]}
        editRoute={Routes.AdminAuthorEdit.route}
        deleteUrl="admin/authors"
        deleteRowTitle={(row) =>
          `автора ${row.personalName} ${row.familyName} (id = ${row.id})`
        }
      />
    </>
  );
};

export default AdminAuthors;
