import React from "react";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";
import Routes from "../../Routes";
import AdminDataList from "../_Proto/AdminDataList";

const AdminTracks = () => {
  return (
    <>
      <h1>Управление треками</h1>
      <div className="my-3">
        <Link to={Routes.AdminTrackCreate.route}>
          <Button color="primary">
            {Routes.AdminTrackCreate.title} <FontAwesomeIcon icon={faPlus} />
          </Button>
        </Link>
      </div>
      <AdminDataList
        url="admin/tracks"
        fields={[
          { name: "id", title: "ID", dataType: "string" },
          { name: "onSale", title: "В продаже", dataType: "boolean" },
          { name: "title", title: "Название", dataType: "string" },
          {
            name: "department",
            title: "Кафедра",
            dataType: "string",
            displayAs: (row) => row.department?.title,
          },
          {
            name: "speciality",
            title: "Специальность",
            dataType: "string",
            displayAs: (row) => row.speciality?.title,
          },
          {
            name: "section",
            title: "Раздел",
            dataType: "string",
            displayAs: (row) => row.section?.title,
          },
        ]}
        editRoute={Routes.AdminTrackEdit.route}
        deleteUrl="admin/Tracks"
        deleteRowTitle={(row) => `лекцию "${row.title || row.id}"`}
      />
    </>
  );
};

export default AdminTracks;
