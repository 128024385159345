import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useAuthContext } from "../_services/authentication.service";
import {
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Spinner,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUserCircle,
  faQuestionCircle,
  faUserEdit,
  faEdit,
} from "@fortawesome/free-solid-svg-icons";
import { userName } from "../_helpers/FormatStrings";
import { Role } from "../_helpers/role";
import "./HeaderAccount.css";
import iconEnter from "../_img/icon_metro-enter.svg";
import Routes from "../Routes";
import { DownloadFile } from "../_components/DownloadPresentation";
import { useKeycloak } from "@react-keycloak/web";

export const HeaderAccount = () => {
  const [keycloak] = useKeycloak();
  const { user, createAccountUrlSso, loginSso } =
    useAuthContext();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isRedirecting, setisRedirecting] = useState(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  return (
    <div className="d-sm-inline-flex flex-sm-row">
      {isRedirecting || (keycloak.authenticated && !user) ? (
        <Spinner size="sm" className="link" />
      ) : keycloak.authenticated && user ? (
        <Dropdown isOpen={dropdownOpen} toggle={toggle}>
          <DropdownToggle tag={Link} to="#">
            {userName(user.fullName)}{" "}
            <FontAwesomeIcon icon={faUserCircle} size="lg" />
          </DropdownToggle>

          <DropdownMenu right>
            {user.roles &&
            user.roles.some((role) =>
              [Role.Admin, Role.Manager].includes(role)
            ) ? (
              <>
                <DropdownItem tag={Link} to={Routes.AdminLectures.route}>
                  {Routes.AdminLectures.title}
                </DropdownItem>
                <DropdownItem tag={Link} to={Routes.AdminTracks.route}>
                  {Routes.AdminTracks.title}
                </DropdownItem>
                <DropdownItem tag={Link} to={Routes.AdminDepartments.route}>
                  {Routes.AdminDepartments.title}
                </DropdownItem>
                <DropdownItem tag={Link} to={Routes.AdminSpecialities.route}>
                  {Routes.AdminSpecialities.title}
                </DropdownItem>
                <DropdownItem tag={Link} to={Routes.AdminSections.route}>
                  {Routes.AdminSections.title}
                </DropdownItem>
                <DropdownItem tag={Link} to={Routes.AdminAuthors.route}>
                  {Routes.AdminAuthors.title}
                </DropdownItem>
                <DropdownItem tag={DownloadFile} url={Routes.AdminHelp.route}>
                  <FontAwesomeIcon icon={faQuestionCircle} />{" "}
                  {Routes.AdminHelp.title}
                </DropdownItem>
                <DropdownItem tag={Link} to={Routes.EditPublicOffer.route}>
                  {Routes.EditPublicOffer.title}
                </DropdownItem>
              </>
            ) : (
              <DropdownItem tag={Link} to={Routes.AccountCatalog.route}>
                {Routes.AccountCatalog.title}
              </DropdownItem>
            )}
            <DropdownItem tag="a" href={createAccountUrlSso()}>
              {Routes.Account.title}
            </DropdownItem>
            <DropdownItem divider />
            <DropdownItem tag={Link} to={Routes.Logout.route}>
              Выйти
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      ) : (
        <button
          onClick={() => {
            setisRedirecting(true);
            loginSso();
          }}
          className="btn btn-link link mx-1"
        >
          Войти <img src={iconEnter} />
        </button>
      )}
    </div>
  );
};
