import React from "react";
import Routes from "../../Routes";
import AdminForm from "../_Proto/AdminForm";
import * as Yup from "yup";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";

const AdminSectionCreate = () => {
  return (
    <>
      <BreadcrumbsItem to={Routes.AdminSectionCreate.route}>
        {Routes.AdminSectionCreate.title}
      </BreadcrumbsItem>
      <h1>Создание раздела</h1>
      <AdminForm
        submitUrl="admin/sections"
        fields={[
          {
            name: "title",
            label: "Название раздела",
            validation: Yup.string().required(
              "Название раздела обязательно для заполнения"
            ),
            inputType: "text",
            acceptFileTypes: "",
          },
        ]}
        redirectTo={Routes.AdminSections.route}
      />
    </>
  );
};

export default AdminSectionCreate;
