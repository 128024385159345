import DefultLecturePoster from "./_img/default-poster.jpg";
import DefultPresentationPreview from "./_img/presentation-preview.png";
import DefultVideoPreview from "./_img/video-preview.png";

export const MediaRoutes = {
  DefultLecturePoster,
  DefultPresentationPreview,
  DefultVideoPreview,
};

const Routes = {
  Home: { route: "/", title: "Главная" },
  Logout: { route: "/logout", title: "Выйти" },

  Catalog: { route: "/catalog", title: "Каталог" },
  Lecture: { route: "/catalog/lecture/:id", title: 'Лекция ":title"' },
  LectureAction: {
    route: "/catalog/lecture/:id/:action",
    title: 'Лекция ":title"',
  },
  Track: { route: "/catalog/track/:id", title: 'Трек ":title"' },
  TrackAction: {
    route: "/catalog/track/:id/:action",
    title: 'Трек ":title"',
  },
  LectureBuy: { route: "/catalog/lecture/:id/buy", title: "Купить лекцию" },
  TrackBuy: { route: "/catalog/track/:id/buy", title: "Купить трек" },

  Account: { route: "/account", title: "Настройки пользователя" },
  AccountCatalog: { route: "/account/catalog", title: "Мои лекции" },

  Admin: { route: "/admin", title: "Управление контентом" },
  AdminHelp: {
    route: "/Marketplace_Administrator_manual.pdf",
    title: "Руководство по управлению лекциями",
  },
  AdminLectures: {
    route: "/admin/lecture",
    title: "Лекции",
  },
  AdminLectureCreate: {
    route: "/admin/lecture/create",
    title: "Создание лекции",
  },
  AdminLectureEdit: {
    route: "/admin/lecture/:id",
    title: 'Редакирование лекции ":title"',
  },
  AdminTracks: {
    route: "/admin/track",
    title: "Треки",
  },
  AdminTrackCreate: {
    route: "/admin/track/create",
    title: "Создание трека",
  },
  AdminTrackEdit: {
    route: "/admin/track/:id",
    title: 'Редакирование трека ":title"',
  },
  AdminAuthors: { route: "/admin/authors", title: "Авторы" },
  AdminAuthorCreate: {
    route: "/admin/authors/create",
    title: "Создание автора",
  },
  AdminAuthorEdit: {
    route: "/admin/authors/edit/:id",
    title: "Редактирование автора",
  },
  AdminDepartments: { route: "/admin/departments", title: "Кафедры" },
  AdminDepartmentCreate: {
    route: "/admin/departments/create",
    title: "Создание кафедры",
  },
  AdminDepartmentEdit: {
    route: "/admin/departments/edit/:id",
    title: "Редактирование кафедры",
  },
  AdminSpecialities: { route: "/admin/specialities", title: "Специальности" },
  AdminSpecialityCreate: {
    route: "/admin/specialities/create",
    title: "Создание специальности",
  },
  AdminSpecialityEdit: {
    route: "/admin/specialities/edit/:id",
    title: "Редактирование специальности",
  },
  AdminSections: { route: "/admin/sections", title: "Разделы" },
  AdminSectionCreate: {
    route: "/admin/sections/create",
    title: "Создание раздела",
  },
  AdminSectionEdit: {
    route: "/admin/sections/edit/:id",
    title: "Редактирование раздела",
  },
  EditPublicOffer: {
    route: "/admin/publicoffer",
    title: "Редактирование публичной оферты",
  },

  VideoStream: { route: "/videostream", title: "Видеотрансляция" },

  getActual(routeName, id, title = "", params = null) {
    let actualRoute = {
      route: this[routeName].route.replace(":id", id),
      title: this[routeName].title.replace(":title", title),
    };
    if (params && params.length > 0) {
      const paramsStr = Object.keys(params)
        .map((key) => `${key}=${params[key]}`)
        .join("&");
      actualRoute.route += "?" + paramsStr;
    }
    return actualRoute;
  },
};

export default Routes;
