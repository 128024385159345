import React, { useState, useEffect } from "react";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Spinner } from "reactstrap";
import { Loading } from "../_components/Loading";
import { SelectFile } from "../_components/UploadFile";
import Routes from "../Routes";
import { useAuthContext } from "../_services/authentication.service";

export const EditPublicOffer = (props) => {
  const [publicOfferInfo, setPublicOfferInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const { requestBackend } = useAuthContext();

  useEffect(() => {
    setLoading(true);
    requestBackend({
      url: "/media/common/publicoffer/info",
      onSuccess: (data) => {
        if (data.updated) {
          data.updated = new Date(data.updated);
        }
        setPublicOfferInfo(data);
        setLoading(false);
      },
      onFail: (err) => {
        console.log(err);
        setLoading(false);
      },
    });
  }, [requestBackend]);

  function SubmitPublicOffer(formData, setStatus, onError) {
    const postData = new FormData();
    postData.append("file", formData.file, formData.file.name);
    return requestBackend({
      url: "/media/common/publicoffer",
      type: "POST",
      data: postData,
      processData: false,
      contentType: false,
      onSuccess: () => {
        setStatus({ type: "success", message: "Файл успешно сохранен" });
      },
      onFail: (_, textStatus, errorThrown) => {
        onError({ status: textStatus, message: errorThrown });
      },
    });
  }

  if (loading) {
    return <Loading />;
  }

  const thisRoute = Routes.EditPublicOffer;

  return (
    <>
      <BreadcrumbsItem to={thisRoute.route}>{thisRoute.title}</BreadcrumbsItem>
      <Formik
        initialValues={{
          file: "",
        }}
        onSubmit={(values, actions) => {
          SubmitPublicOffer(values, actions.setStatus, (error) => {
            if (error.status == "404" || error.message === "Not Found")
              actions.setStatus({ type: "danger", message: "Файл не найден" });
            else if (error.status == "401" || error.message === "Unauthorized")
              actions.setStatus({
                type: "danger",
                message: "Ошибка авторизации. Попробуйте выйти и войти снова",
              });
            else
              actions.setStatus({
                type: "danger",
                message: "Произошла ошибка при отправке данных на сервер",
              });
          }).finally(() => {
            actions.setStatus();
            actions.setSubmitting(false);
          });
        }}
      >
        {({ errors, status, touched, isSubmitting, setFieldValue }) => (
          <Form>
            <div className="form-group">
              <label htmlFor="file">Договор публичной оферты pdf/docx</label>
              <Field
                name="file"
                type="text"
                className={
                  "form-control" +
                  (errors.presentationurl && touched.presentationurl
                    ? " is-invalid"
                    : "")
                }
              >
                {({ field: { value }, form: { setFieldValue } }) => (
                  <SelectFile
                    accept="application/pdf,application/vnd.ms-word,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                    prevFileId={publicOfferInfo.fileId}
                    prevFileName={publicOfferInfo.fileName}
                    onChange={(file) => {
                      setFieldValue("file", file);
                    }}
                  />
                )}
              </Field>
              <ErrorMessage
                name="file"
                component="div"
                className="invalid-feedback"
              />
              <div>
                <em>Последнее обновление: {publicOfferInfo.updated.toLocaleString()}</em>
              </div>
            </div>

            <div className="form-group">
              <button
                type="submit"
                className="btn btn-primary"
                disabled={isSubmitting}
              >
                Сохранить
              </button>
              <button
                type="reset"
                className="btn btn-light btn-outer-primary"
                disabled={isSubmitting || errors}
              >
                Отменить
              </button>
              {isSubmitting ? (
                <Spinner />
              ) : (
                <ErrorMessage
                  name="general"
                  component="div"
                  className="invalid-feedback"
                />
              )}
            </div>
            {status && (
              <div className={"alert alert-" + (status.type || "danger")}>
                {status.message}
              </div>
            )}
          </Form>
        )}
      </Formik>
    </>
  );
};
