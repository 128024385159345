import React, { useEffect, useState } from "react";
import $ from "jquery";
import { getBackendApiUrl } from "../_helpers/backend.url";
import { Role } from "../_helpers/role";
import { useContext, createContext } from "react";
import keycloak from "./keycloak";
import { KeycloakProvider } from "@react-keycloak/web";
import { useHistory } from "react-router-dom";

const keycloakProviderInitConfig = {
  onLoad: "check-sso",
};

export const AuthContext = createContext({
  user: null,
  loginSso: () => {},
  logout: () => {},
  updateUser: () => {},
  createAccountUrlSso: () => "",
  requestBackend: (url, type, data, onSuccess, onFail) => {},
});
export const useAuthContext = () => useContext(AuthContext);

export const AuthContextProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const history = useHistory();

  function rememberCurrentUser(user) {
    if (user && !user.roles?.length) {
      user.roles = [Role.User];
    }
    setUser(user);
  }

  function getUserFromToken(userInfo) {
    return {
      userId: userInfo.sub,
      firstname: userInfo.given_name,
      middlename: userInfo.middle_name,
      lastname: userInfo.family_name,
      fullName:
        userInfo.given_name +
        " " +
        (userInfo.middle_name ? userInfo.middle_name + " " : "") +
        userInfo.family_name,
      email: userInfo.email,
      roles: userInfo.roles,
    };
  }

  function loginSso() {
    //console.log("loginSso", keycloak.authenticated, Object.keys(keycloak));
    if (!keycloak.authenticated) {
      const url = keycloak.createLoginUrl({
        redirectUri: history.location.href,
      });
      window.location.href = url;
    }
  }

  function logout() {
    setUser(null);
    if (keycloak.authenticated) {
      keycloak.logout();
    }
  }

  async function requestBackend(props) {
    const { url, type = "GET", data, onSuccess, onFail, ...otherProps } = props;
    let headers = props.headers || {};
    if (keycloak.authenticated) {
      headers["Authorization"] = "Bearer " + keycloak.token;
    }
    return $.ajax({
      ...otherProps,
      type: type,
      url: getBackendApiUrl(url),
      data: data,
      headers: headers,
      success: onSuccess,
      error:
        onFail ||
        ((_, status, err) => {
          console.error(
            `Status ${status}`,
            err,
            type,
            getBackendApiUrl(url),
            data
          );
        }),
    });
  }

  function createAccountUrlSso() {
    if (keycloak.authenticated) {
      return keycloak.createAccountUrl();
    }
    return `${keycloak.url}/realms/${keycloak.realm}/account`;
  }

  const onKeycloakEvent = (event, error) => {
    //console.log("onKeycloakEvent", event, error);
    if (keycloak.authenticated && event === "onAuthSuccess") {
      rememberCurrentUser(getUserFromToken(keycloak.idTokenParsed));
    }
    if (event === "onTokenExpired") {
      keycloak
        .updateToken(5)
        .then((refreshed) => {
          if (refreshed) {
            console.log("Token was successfully refreshed");
          } else {
            console.log("Token is still valid");
          }
        })
        .catch(function () {
          console.log(
            "Failed to refresh the token, or the session has expired"
          );
        });
    }
  };

  const onKeycloakTokens = (tokens) => {
    //console.log("onKeycloakTokens", tokens);
    if (keycloak.authenticated) {
      rememberCurrentUser(getUserFromToken(keycloak.idTokenParsed));
    }
  };

  return (
    <KeycloakProvider
      keycloak={keycloak}
      initConfig={keycloakProviderInitConfig}
      onEvent={onKeycloakEvent}
      onTokens={onKeycloakTokens}
    >
      <AuthContext.Provider
        value={{
          user,
          loginSso,
          logout,
          requestBackend,
          createAccountUrlSso,
        }}
      >
        {children}
      </AuthContext.Provider>
    </KeycloakProvider>
  );
};
