import React from "react";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { Route, Switch } from "react-router-dom";
import { Catalog } from "./Catalog";
import { LecturePage } from "./LecturePage";
import { PrivateRoute } from "../_components/PrivateRoute";
import { TrackPage } from "./TrackPage";
import Routes from "../Routes";

export const CatalogPage = () => (
  <>
    <BreadcrumbsItem to={Routes.Catalog.route}>
      {Routes.Catalog.title}
    </BreadcrumbsItem>
    <Switch>
      <PrivateRoute path={Routes.LectureAction.route} component={LecturePage} />
      <PrivateRoute path={Routes.TrackAction.route} component={TrackPage} />
      <Route path={Routes.Lecture.route} component={LecturePage} />
      <Route path={Routes.Track.route} component={TrackPage} />
      <Route component={Catalog} />
    </Switch>
  </>
);
