import React, { useEffect, useState } from "react";
import $ from "jquery";
import PropTypes from "prop-types";
import { FilterSelect } from "../_components/Filter";
import { QueryParams } from "./QueryParams";
import { useAuthContext } from "../_services/authentication.service";
import { Loading } from "../_components/Loading";

export function getFiltersFromParams(params) {
  var filterParams = {};
  params.forEach((value, key) => {
    if (key.startsWith(QueryParams.filters)) {
      filterParams = {
        ...filterParams,
        [key.substr(QueryParams.filters.length)]: { value, options: [] },
      };
    }
  });
  return filterParams;
}

const CatalogFilters = ({
  filters,
  setFilterValue,
  groupByTrack,
  setGroupByTrack,
}) => {
  const { requestBackend } = useAuthContext();
  const [loading, setLoading] = useState(true);
  const [departments, setDepartments] = useState([]);
  const [specialities, setSpecialities] = useState([]);
  const [sections, setSections] = useState([]);

  useEffect(() => {
    if (!loading) return;
    Promise.all([
      $.when(
        requestBackend({
          url: "catalog/departments",
          onSuccess: (data) => {
            setDepartments(data);
          },
        })
      ),
      $.when(
        requestBackend({
          url: "catalog/specialities",
          onSuccess: (data) => {
            setSpecialities(data);
          },
        })
      ),
      $.when(
        requestBackend({
          url: "catalog/sections",
          onSuccess: (data) => {
            setSections(data);
          },
        })
      ),
    ]).then(function () {
      setLoading(false);
    });
  }, [loading]);

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <FilterSelect
        label="Раздел"
        selectedOption={filters.section?.value ?? ""}
        options={sections}
        onFilter={(value) => setFilterValue("section", value)}
      />
      <FilterSelect
        label="Специальность"
        selectedOption={filters.speciality?.value ?? ""}
        options={specialities}
        onFilter={(value) => setFilterValue("speciality", value)}
      />
      <FilterSelect
        label="Кафедра"
        selectedOption={filters.department?.value ?? ""}
        options={departments}
        onFilter={(value) => setFilterValue("department", value)}
      />
      <div className="form-check">
        <input
          type="checkbox"
          id="check-groupbyTrack"
          className="form-check-input"
          checked={groupByTrack}
          onChange={() => setGroupByTrack((groupByTrack) => !groupByTrack)}
        />
        <label className="form-check-label" htmlFor="check-groupbyTrack">
          Группировать в треки
        </label>
      </div>
    </>
  );
};

CatalogFilters.propTypes = {
  filters: PropTypes.object,
  setFilterValue: PropTypes.func,
  groupByTrack: PropTypes.bool,
  setGroupByTrack: PropTypes.func,
};

export default CatalogFilters;
